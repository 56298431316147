const styles = (theme) => ({
  staticText: {
    marginLeft: 0,
    fontWeight: 900,
    lineHeight: 1.7,
  },

  // text: {
  //   lineHeight: theme.palette.font.paraLineHeight,
  //   fontSize: 15,
  //   [theme.breakpoints.down(1045)]: {
  //     fontSize: 14,
  //   },
  //   [theme.breakpoints.down(719)]: {
  //     fontSize: 12,
  //   },
  //   [theme.breakpoints.down(600)]: {
  //     fontSize: 16,
  //   },
  //   [theme.breakpoints.down(415)]: {
  //     fontSize: 14,
  //   },
  //   [theme.breakpoints.down(321)]: {
  //     fontSize: 12,
  //   },
  // },
});

export default styles;
