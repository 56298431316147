import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

// Utils Stuff
import themeObjectContent from "./utils/theme";

// MUI Stuff
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
//import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

import { makeStyles, createMuiTheme } from "@material-ui/core/styles";

// Pages
import Navbar from "./components/Layout/Navbar/Navbar";
import Navbar2 from "./components/Layout/Navbar2/Navbar2";
import Sidebar from "./components/Layout/Sidebar/Sidebar";
import Footer from "./components/Layout/Footer/Footer";
import LandingPage from "./pages/landingPage/landingPage";
import Users from "./pages/users/users";
import Branches from "./pages/branches/branches";
import Employees from "./pages/employees/employees";
import Registration from "./pages/registration/registration";
import Login from "./pages/login/login";
import EditBranch from "./pages/editBranch/editBranch";
import EditCompanies from "./pages/editCompanies/editCompanies";
import EditUser from "./pages/editUser/editUser";
import Vehicles from "./pages/vehicles/vehicles";
import VehiclesMore from "./pages/vehiclesMore/vehiclesMore";
import AddBranch from "./pages/addBranch/addBranch";

const theme = createMuiTheme(themeObjectContent);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

const RouteWithNavbar2 = ({ exact, path, component: Component, ...rest }) => {
  return (
    <Route
      exact={exact}
      path={path}
      {...rest}
      render={(routeProps) => {
        return (
          <>
            <Navbar2 {...routeProps} />
            <Component {...routeProps} />
            <Footer />
          </>
        );
      }}
    />
  );
};
const RouteWithNavbar = ({ exact, path, component: Component, ...rest }) => {
  return (
    <Route
      exact={exact}
      path={path}
      {...rest}
      render={(routeProps) => {
        return (
          <>
            <Navbar {...routeProps} />
            <Component {...routeProps} />
            <Footer />
          </>
        );
      }}
    />
  );
};

const RouteWithSidebar = ({ exact, path, component: Component, ...rest }) => {
  const classes = useStyles();
  return (
    <Route
      exact={exact}
      path={path}
      {...rest}
      render={(routeProps) => {
        return (
          <div className={classes.root}>
            <Sidebar {...routeProps} />
            <Component {...routeProps} />
          </div>
        );
      }}
    />
  );
};

const App = () => {
  const browser = (route) => window.location.toString().indexOf(route);
  return (
    <MuiThemeProvider theme={theme}>
      <div>
        <Router>
          <div
            className={
              browser("branches") > -1 ||
              browser("users") > -1 ||
              browser("employees") > -1 ||
              browser("vehicles") > -1
                ? "container2"
                : "container"
            }
          >
            <Switch>
              <RouteWithNavbar2 exact path="/" component={LandingPage} />
              <RouteWithNavbar2 exact path="/home" component={LandingPage} />
              <RouteWithNavbar
                exact
                path="/contact-us"
                component={Registration}
              />
              <RouteWithNavbar exact path="/login" component={Login} />
              <RouteWithSidebar exact path="/employees" component={Employees} />
              <RouteWithSidebar exact path="/users" component={Users} />
              <RouteWithSidebar exact path="/branches" component={Branches} />
              <RouteWithSidebar
                exact
                path="/branches/edit"
                component={EditBranch}
              />
              <RouteWithSidebar
                exact
                path="/companies/edit"
                component={EditCompanies}
              />
              <RouteWithSidebar
                exact
                path="/user/edit"
                component={EditUser}
              />
              <RouteWithSidebar exact path="/vehicles" component={Vehicles} />
              <RouteWithSidebar
                exact
                path="/vehicles/more"
                component={VehiclesMore}
              />
              <RouteWithSidebar
                exact
                path="/branches/add"
                component={AddBranch}
              />
            </Switch>
          </div>
        </Router>
      </div>
    </MuiThemeProvider>
  );
};

export default App;
