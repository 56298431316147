import React from "react";
import PropTypes from "prop-types";
import TypographyText from "@material-ui/core/Typography";

export const Typography = (props) => {
  const { className, color, variant, gutterBottom, children } = props;
  return (
    <TypographyText
      color={color}
      variant={variant}
      className={className}
      gutterBottom={gutterBottom}
    >
      {children}
    </TypographyText>
  );
};

Typography.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
};
