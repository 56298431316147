import React from "react";
import PropTypes from "prop-types";
import CardItem from "@material-ui/core/Card";

export const Card = (props) => {
  const { className, key, children } = props;
  return (
    <CardItem className={className} key={key}>
      {children}
    </CardItem>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  key: PropTypes.string || PropTypes.number,
  children: PropTypes.node,
};
