const drawerWidth = 270;

const styles = (theme) => ({
  toolbarContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.color.white,
    fontFamily: theme.palette.font.montserrat,
    height: "100vh",
    marginTop: -8,
    marginBottom: -18,
    overflowY: "hidden",
    width: drawerWidth,
    [theme.breakpoints.down(541)]: {
      overflowY: "scroll",
    },
  },
  chevron: {
    color: theme.palette.color.white,
    fontSize: 25,
    marginLeft: -15,
  },
  toolbarText: {
    fontFamily: `${theme.palette.font.montserrat} !important`,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
  },
  menuContainer: {
    marginTop: 90,
  },
  logoutContainer: {
    // marginTop: "50%",
    marginLeft: 15,
    display: "flex",
    "&:hover": {
      color: theme.palette.secondary.main,
      cursor: "pointer",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "65%",
    },
    [theme.breakpoints.down(541)]: {
      marginTop: "40%",
    },
    [theme.breakpoints.down(415)]: {
      marginTop: "45%",
    },
  },
  logoutTextContainer: {
    marginLeft: 13,
    marginTop: -11,
  },
  logoutText: {
    fontSize: 15,
  },
  divider: {
    backgroundColor: theme.palette.color.grey,
  },
  divider1: {
    marginBottom: 35,
    backgroundColor: theme.palette.color.grey,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up(541)]: {
      display: "none",
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    height: "100vh",
    overflowX: "hidden",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7.4) + 1,
    },
    [theme.breakpoints.down(541)]: {
      width: theme.spacing(0) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  sidebarTab: {
    height: "60vh",
    overflowY: "scroll",
  },

  textTab: {
    fontSize: 13,
  },
});

export default styles;
