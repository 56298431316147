const styles = (theme) => ({
  tabContainer: {
    backgroundColor: theme.palette.color.pinkGrey,
    margin: "10px",
    [theme.breakpoints.down(960)]: {
      marginTop: 90,
    },
    [theme.breakpoints.down(760)]: {
      marginTop: 130,
    },
  },

  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.color.skyBlue}`,
  },
  tabPageContainer: {
    minHeight: "72vh",
    fontFamily: theme.palette.font.montserrat,
  },
  tab: {
    backgroundColor: theme.palette.color.skyBlue,
    color: theme.palette.color.thickGreen,
    fontWeight: 900,
  },
  iconContainer: {
    paddingLeft: 10,
  },
  tabItem: {
    // backgroundColor: "red",
  },
  labelTitle: {
    display: "flex",
    [theme.breakpoints.down(415)]: {
      fontSize: 12,
      marginBottom: -10,
    },
    [theme.breakpoints.down(376)]: {
      fontSize: 10,
    },
    [theme.breakpoints.down(361)]: {
      fontSize: 8,
      marginBottom: -15,
    },
  },
});

export default styles;
