import React from "react";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

// Components
import {Typography } from "../../components";
import { ProfileForm } from "../../components/EmployeeForm/ProfileForm";

// styles
import styles from "./Styles";

// images
import CloseImg from "../../images/close.svg";

const BranchForms = (props) => {
  const { classes, employee, onClickClose, closeImg, addOrEditEmployee } = props;

  return (
    <Grid container className={classes.editBranchPageWrapper}>
      <Grid
        container
        item
        xs={12}
        sm={12}
        className={classes.editBranchPageContainer}
      >
        {closeImg && (
          <Grid item xs={12} sm={12}>
            <div className={classes.header}>
              <div onClick={onClickClose}>
                <img src={CloseImg} alt="photo" className={classes.logo} />
              </div>
            </div>
          </Grid>
        )}

        <Grid item xs={12} sm={12} className={classes.tabContainer}>
          <div className={classes.tabContainer}>
            <Typography>
              <ProfileForm
                employee={employee}
                header="Employee Information"
                addOrEditEmployee={addOrEditEmployee}
              />
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(BranchForms);
