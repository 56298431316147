import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// MUI Stuff
import withStyles from "@material-ui/core/styles/withStyles";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

// styles
import styles from "./Styles";

// Components
import { Typography, Tooltip, Card, Divider } from "../../components";
import vehicles from "../../pages/vehicles/vehicles";

const VehicleCard = (props) => {
  const { classes, vehicleImage, vehicleBrand, vehicleDate, vehicleYear, key } =
    props;
  let history = useHistory();
  return (
    <Grid item xs={12} sm={4} md={4} lg={3} className={classes.cardWrapper}>
      <Card className={classes.card} key={key}>
        <div className={classes.cardMedia}>
          <CardMedia className={classes.media} image={vehicleImage} />
        </div>

        <CardContent className={classes.contentWrapper}>
          <div style={{ display: "flex", marginTop: -20 }}>
            <div
              style={{
                width: "70%",
                color: "#FFFFFF",
                fontWeight: "bold",
              }}
            >
              <div
                style={{
                  width: "100%",
                  paddingTop: "18%",
                }}
              >
                {vehicleYear} {vehicleBrand}
              </div>
            </div>
            <div
              style={{
                width: "30%",
                borderLeft: "25px solid transparent",
                borderBottom: "50px solid #00CCFF",
                borderRight: "0 solid transparent",
                position: "relative",
              }}
            >
              <Button
                style={{
                  marginBottom: -60,
                  width: "100%",
                  marginTop: "3%",
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                }}
                onClick={() => history.push("/vehicles/more")}
              >
                MORE
              </Button>
            </div>
          </div>
          <div style={{ margin: "10px auto" }}>
            <Divider backgroundColor="white" />
            <div
              style={{
                width: "100%",
                color: "#FFFFFF",
                fontSize: 10,
              }}
            >
              Due date: {vehicleDate}
            </div>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

VehicleCard.propTypes = {
  classes: PropTypes.object.isRequired,
  readMore: PropTypes.string,
  vehicleBrand: PropTypes.string.isRequired,
  branchAddress: PropTypes.string.isRequired,
  vehicleImage: PropTypes.string.isRequired,
};

export default withStyles(styles)(VehicleCard);
