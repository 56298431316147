const styles = (theme) => ({
  textField: {
    marginBottom: 4,
    right: 20,
    position: "relative",
    [theme.breakpoints.down(960)]: {
      right: "-4.4%",
      margin: "auto",
    },
    [theme.breakpoints.down(820)]: {
      right: "-5.5%",
      width: "98%",
    },
    [theme.breakpoints.down(674)]: {
      right: "-6.5%",
      width: "96%",
    },
    [theme.breakpoints.down(600)]: {
      right: 0,
      width: "94%",
      marginLeft: "3%",
    },
  },
  textField2: {
    marginBottom: 4,
    left: "4.5%",
    [theme.breakpoints.down(600)]: {
      right: 0,
      width: "94%",
      marginLeft: "3%",
    },
  },
  textField3: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        marginBottom: 4,
        marginLeft: -20,
        right: 20,
      },
    },

    [theme.breakpoints.down(600)]: {
      right: 0,
      width: "94%",
      marginLeft: "3%",
    },
  },
  label: {
    paddingLeft: 25,
    marginTop: 5,
    fontFamily: theme.palette.font.montserrat,
    fontSize: 13,
    [theme.breakpoints.down(415)]: {
      marginLeft: -5,
    },
    [theme.breakpoints.down(321)]: {
      marginLeft: -8,
    },
    [theme.breakpoints.down(281)]: {
      marginLeft: -10,
    },
  },

  cssLabel: {
    color: theme.palette.color.thickGreen,
    fontSize: 13.5,
    fontFamily: theme.palette.font.montserrat,
    "&.Mui-focused": {
      color: theme.palette.color.skyBlue,
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.color.skyBlue,
    },
  },

  cssOutlinedInput: {
    color: theme.palette.primary.main,
    height: 30,
    "&$cssFocused $notchedOutline": {
      borderColor: `${theme.palette.color.skyBlue} !important`,
    },
  },

  cssFocused: {
    fontFamily: theme.palette.font.montserrat,
  },

  notchedOutline: {
    borderWidth: "1px",
  },
  dividerWrapper: {
    marginLeft: "15px",
    marginRight: "13px",
    [theme.breakpoints.down(541)]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
});

export default styles;
