// MUI stuff
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { Typography } from "..";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ShortTextField from "../TextField/ShortTextField";
import { Button } from "..";

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: theme.palette.font.montserrat,
    height: 20,
    backgroundColor: theme.palette.color.lighterGrey,
    fontSize: 12,
    fontWeight: "bold",
    width: "100%",
    paddingLeft: 10,
  },
  leftContainer: {
    borderRight: `1px solid ${theme.palette.color.lighterGrey}`,
  },
  tableContainer: {
    border: `1px solid ${theme.palette.color.lighterGrey}`,
    margin: "auto 10px 10px 10px",
    minHeight: 280,
  },
  rightContainer: {},
}));

export const PhoneForm = ({ header }) => {
  const classes = useStyles();
  return (
    <Paper elevation={0}>
      <Grid item xs={12} sm={12} className={classes.header}>
        <Typography variant="p">{header}</Typography>
      </Grid>
      <Grid container item xs={12} sm={12} className={classes.form}>
        <Grid item xs={12} sm={6} className={classes.leftContainer}>
          <Box mt={0} pt={1}>
            trashIcon
          </Box>
          <Paper elevation={0} className={classes.tableContainer}>
            xdsfg
          </Paper>
        </Grid>

        <Grid container item xs={12} sm={6} className={classes.rightContainer}>
          <Grid
            container
            spacing={3}
            style={{ marginTop: 20, marginBottom: 15 }}
            className={classes.rightContainerdd}
          >
            <ShortTextField
              autofocus
              name="phone"
              label="Phone :"
              type="text"
              // icon={<BusinessIcon />}
            />
            <ShortTextField
              name="extension"
              label="Extension :"
              type="text"
              // icon={<BusinessIcon />}
            />
            <ShortTextField
              name="type"
              label="Type :"
              type="text"
              // icon={<BusinessIcon />}
            />

            <ShortTextField
              name="comments"
              label="Comments :"
              type="text"
              rows={3}
              multiline
            />
            <div style={{ width: 200, margin: "10px auto auto auto" }}>
              {" "}
              <Button>Save</Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
