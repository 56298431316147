// MUI stuff
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { Typography } from "..";
import { makeStyles } from "@material-ui/core/styles";
import ShortTextField from "../TextField/ShortTextField";
import TextFieldDate from "../TextField/TextFieldDate";
import { Button } from "..";
import { MenuItem } from "@material-ui/core";

// components
import TextEditor from"../TextEditor/TextEditor";

const licenceList = ["Licence 1", "Licence 2", "Licence 3"];

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: theme.palette.font.montserrat,
    height: 20,
    backgroundColor: theme.palette.color.lighterGrey,
    fontSize: 12,
    fontWeight: "bold",
    width: "100%",
    paddingLeft: 10,
  },
  leftContainer: {
    borderRight: `1px solid ${theme.palette.color.lighterGrey}`,
  },
  tableContainer: {
    border: `1px solid ${theme.palette.color.lighterGrey}`,
    margin: "auto 10px 10px 10px",
    minHeight: 225,
  },
  tableEditorContainer: {
    border: `1px solid ${theme.palette.color.lighterGrey}`,
    width: "90%",
    margin: "auto",
    
  },
  tableContainerPart2: {
    border: `1px solid ${theme.palette.color.lighterGrey}`,
    margin: "auto 10px 10px 10px",
    minHeight: 195,
  },
  rightContainer: {},
  section: {
    marginLeft: 20,
    fontSize: 11,
    fontFamily: theme.palette.font.montserrat,
    backgroundColor: theme.palette.color.simpleWhite,
    paddingLeft: 5,
    paddingRight: 5,
    color: theme.palette.color.orange,
  },
}));

export const ProfileForm = ({ header }) => {
  const classes = useStyles();
  return (
    <Paper elevation={0}>
      <Grid item xs={12} sm={12} className={classes.header}>
        <Typography variant="p">{header}</Typography>
      </Grid>
      <Grid container item xs={12} sm={12} className={classes.form}>
        <Grid item xs={12} sm={6} className={classes.leftContainer}>
          <Typography variant="p" className={classes.section}>
            Identification
          </Typography>
          <Paper elevation={0} className={classes.tableContainer}>
            <Grid
              container
              spacing={3}
              style={{ marginTop: 20, marginBottom: 15 }}
              className={classes.rightContainerdd}
            >
              <ShortTextField
                autofocus
                name="company"
                label="Licence :"
                type="text"
                defaultValue=""
                select
                fullWidth
                list={licenceList.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
                // icon={<BusinessIcon />}
              />
              <ShortTextField
                name="code"
                label="Code :"
                type="text"
                // icon={<BusinessIcon />}
              />
              <ShortTextField
                name="nom"
                label="Nom :"
                type="text"
                // icon={<BusinessIcon />}
              />
              <ShortTextField
                name="email"
                label="E-mail :"
                type="text"
                // icon={<BusinessIcon />}
              />
              <TextFieldDate label="Issued Date" />
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.leftContainer}>
          <Typography variant="p" className={classes.section}>
            Memo
          </Typography>
          <Paper elevation={0} className={classes.tableContainer}>
            <Grid
              container
              spacing={3}
              style={{ marginTop: 20, marginBottom: 15 }}
              className={classes.rightContainerdd}
            >
              <div className={classes.tableEditorContainer}>
                <TextEditor />
              </div>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.leftContainer}>
          <Typography variant="p" className={classes.section}>
            Main address
          </Typography>
          <Paper elevation={0} className={classes.tableContainerPart2}>
            <Grid
              container
              spacing={3}
              style={{ marginTop: 20, marginBottom: 15 }}
              className={classes.rightContainerdd}
            >
              <ShortTextField
                autofocus
                name="idNumber"
                label="ID Number :"
                type="text"
                // icon={<BusinessIcon />}
              />
              <ShortTextField
                name="street"
                label="Street :"
                type="text"
                // icon={<BusinessIcon />}
              />
              <ShortTextField
                name="country"
                label="Country :"
                type="text"
                // icon={<BusinessIcon />}
              />
              <ShortTextField
                name="province"
                label="Province :"
                type="text"
                // icon={<BusinessIcon />}
              />
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.leftContainer}>
          <Typography variant="p" className={classes.section}>
            Main phone
          </Typography>
          <Paper elevation={0} className={classes.tableContainerPart2}>
            <Grid
              container
              spacing={3}
              style={{ marginTop: 20, marginBottom: 15 }}
              className={classes.rightContainerdd}
            >
              <ShortTextField
                autofocus
                name="type"
                label="Type :"
                type="text"
                // icon={<BusinessIcon />}
              />
              <ShortTextField
                name="number"
                label="Number :"
                type="text"
                // icon={<BusinessIcon />}
              />
              <ShortTextField
                name="extension"
                label="Extension :"
                type="text"
                // icon={<BusinessIcon />}
              />
            </Grid>
          </Paper>
        </Grid>
        <div style={{ width: 200, margin: "10px auto 10px auto" }}>
          {" "}
          <Button>Save</Button>
        </div>
      </Grid>
    </Paper>
  );
};
