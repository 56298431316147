import React, { useEffect } from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { usePagination } from "@material-ui/lab/Pagination";
import Tabs from "../../components/Tabs/Tabs";

// Components
import { SimpleCard, AddVehicleForm } from "../../components";

// styles
import styles from "./Styles";

// Database
import vehicleDB from "../../utils/dbVehicles";

// Images
import Car from "../../images/car1.jpeg";

const Vehicles = (props) => {
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState("");
  const [filterVehicles, setFilterVehicles] = React.useState([]);
  const [inputSearch, setInputSearch] = React.useState("");

  const handleOpen = (id) => {
    const application = vehicleDB.vehicles.find((app) => app.vehicleId === id);
    setOpen(true);
    setItem(application);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setFilterVehicles(
      vehicleDB.vehicles.filter((data) =>
        data.brand.toLowerCase().includes(inputSearch.toLowerCase())
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, vehicleDB.vehicles]);

  const { items } = usePagination({
    count: 8,
  });

  console.log("items:", items);
  const { classes } = props;
  return (
    <Grid container className={classes.branchPageWrapper}>
      <Grid
        container
        item
        xs={12}
        sm={12}
        className={classes.moreVehicleContainer}
      >
        <Grid
          container
          item
          xs={12}
          sm={12}
          className={classes.displayVehicles}
        >
          <Grid container className={classes.insight}>
            <Grid
              container
              spacing={3}
              item
              sm={12}
              xs={12}
              className={classes.mediaWrapper}
            >
              <Grid
                container
                item
                sm={12}
                xs={12}
                className={classes.mediaWrapperasd}
              >
                <Grid item sm={4} xs={12} className={classes.mediaWrapperasd}>
                  {/* <Paper elevation={1} className={classes.paper}> */}
                  <div className={classes.icon}>
                    <img src={Car} alt="logo" className={classes.image} />
                  </div>
                  {/* </Paper> */}
                </Grid>
                <Grid item sm={8} xs={12} className={classes.mediaWrapperasd}>
                  <Paper elevation={1} className={classes.paper}>
                    ASDFG
                  </Paper>
                </Grid>
              </Grid>
              <Grid item sm={12} xs={12} className={classes.mediaWrapper}>
                <div style={{ marginLeft: -20 }}>
                  <Tabs
                    tip="Add a vehicle"
                    tabLabel="Add vehicle"
                    pageTab0={
                      <Grid
                        container
                        item
                        xs={12}
                        sm={12}
                        className={classes.usersContainer}
                      >
                        <Grid
                          container
                          spacing={3}
                          item
                          sm={12}
                          xs={12}
                          className={classes.mediaWrapper}
                        >
                          <SimpleCard>
                            <AddVehicleForm />
                          </SimpleCard>
                        </Grid>
                      </Grid>
                    }
                    tip1="Edit this vehicle"
                    tabLabel1="Edit Vehicle"
                    pageTab1={
                      <Grid
                        container
                        item
                        sx={12}
                        sm={12}
                        style={{ margin: "auto" }}
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          sm={12}
                          className={classes.addUserFormContainer}
                        >
                          {/* <SimpleCard>
                          <AddEmployeeForm />
                        </SimpleCard> */}
                          sdfghj
                        </Grid>
                      </Grid>
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
Vehicles.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Vehicles);
