import React, { useState } from "react";

// MUI stuff
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { Typography } from "..";
import { makeStyles } from "@material-ui/core/styles";
import ShortTextField from "../TextField/ShortTextField";
import { Button } from "..";
import { MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: theme.palette.font.montserrat,
    height: 20,
    backgroundColor: theme.palette.color.lighterGrey,
    fontSize: 12,
    fontWeight: "bold",
    width: "100%",
    paddingLeft: 10,
  },
  leftContainer: {
    borderRight: `1px solid ${theme.palette.color.lighterGrey}`,
  },
  tableContainer: {
    margin: "auto 10px 10px 10px",
    marginTop: 30,
  },
  tableContainerPart2: {
    border: `1px solid ${theme.palette.color.lighterGrey}`,
    margin: "auto 10px 10px 10px",
    minHeight: 195,
  },
  rightContainer: {
    borderStyle: "groove",
    height: 180,
    margin: "auto 30px 30px 30px",
  },
  section: {
    marginLeft: 20,
    fontSize: 11,
    fontFamily: theme.palette.font.montserrat,
    backgroundColor: theme.palette.color.simpleWhite,
    paddingLeft: 5,
    paddingRight: 5,
    color: theme.palette.color.orange,
  },
}));

export const OptionsForm = (props) => {
  const { header, label, list, defaultValue } = props;

  const classes = useStyles();

  return (
    <Paper elevation={0}>
      <Grid item xs={12} sm={12} className={classes.header}>
        <Typography variant="p">{header}</Typography>
      </Grid>
      <Grid container item xs={12} sm={12} className={classes.form}>
        <Grid item xs={5} sm={5} md={5} className={classes.tableContainer}>
          <ShortTextField
            autofocus
            name="category"
            label={label}
            type="text"
            defaultValue={defaultValue}
            select
            fullWidth
            list={list.map((location) => (
              <MenuItem key={location} value={location}>
                {location}
              </MenuItem>
            ))}
            // icon={<BusinessIcon />}
          />
        </Grid>

        <Grid item xs={12} sm={12} className={classes.rightContainer}></Grid>

        <div style={{ width: 200, margin: "10px auto 10px auto" }}>
          {" "}
          <Button>Save </Button>
        </div>
      </Grid>
    </Paper>
  );
};
