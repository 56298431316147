import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import FilledInput from "@material-ui/core/FilledInput";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  label: {
    paddingLeft: 25,
    marginTop: 5,
    fontFamily: theme.palette.font.montserrat,
    fontSize: 13,
  },
  margin: {
    marginBottom:5,
  },
  textField: {
    marginBottom: 4,
    right: 20,
    [theme.breakpoints.down(600)]: {
      right: 0,
      width: "94%",
      marginLeft: "3%",
    },
  },
}));

export default function InputAdornments(props) {

  const { label, user, addOrEditUser } = props;
  const classes = useStyles();
  const [password, setPassword] = React.useState(
    addOrEditUser == "edit" ? user.password : ""
  );
  const [showPassword, setShowPassword] = React.useState(false);

  const handleChange = () => (event) => {
    setPassword(event.target.value );
  };

  const handleClickShowPassword = () => {
    setShowPassword( !showPassword );
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Grid container className={classes.margin}>
        <Grid item xs={12} sm={7} md={5} className={classes.label}>
          {label}
        </Grid>
        <Grid item xs={12} sm={5} md={7}>
          <FormControl
            className={classes.textField}
            variant="outlined"
            size="small"
            fullWidth
          >
            <OutlinedInput
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handleChange()}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
