const styles = (theme) => ({
  landingPageWrapper: {
    flexGrow: 1,
  },
  landingPageContainer: {
    margin: "auto",
    minHeight: "84.4vh",
  },
  aboutUsSection: {
    margin: "130px auto auto auto",
    [theme.breakpoints.down(541)]: {
      margin: "20px auto auto auto",
    },
    [theme.breakpoints.down(376)]: {
      margin: "-60px auto auto auto",
    },
    [theme.breakpoints.down(376)]: {
      margin: "-10px auto auto auto",
    },
  },
  aboutUsContainer: {
    margin: "80px auto",
    [theme.breakpoints.down(600)]: {
      margin: "40px auto auto auto",
    },
  },
  whatWeDoContainer: {
    margin: "80px auto",
    [theme.breakpoints.down(600)]: {
      margin: "10px auto auto auto",
    },
  },
  subHeader: {
    color: theme.palette.color.orange,
    fontFamily: theme.palette.font.montserrat,
    fontWeight: "bold",
  },

  ourServicesContainer: {
    marginBottom: 70,
  },

  ourServices: {
    margin: "80px auto",
    [theme.breakpoints.down(600)]: {
      margin: "40px auto auto auto",
    },
  },

  services: {
    margin: "0px auto",
    [theme.breakpoints.down(600)]: {
      margin: "40px auto auto auto",
    },
  },
  topClients: {
    margin: "40px auto 40px auto",
    [theme.breakpoints.down(600)]: {
      margin: "20px auto 40px auto",
    },
  },

  headerCentered: {
    color: theme.palette.color.skyBlue,
    fontFamily: theme.palette.font.montserrat,
    margin: "auto auto 20px auto",
    textAlign: "center",
    textTransform: "uppercase",
    [theme.breakpoints.down(960)]: {
      fontSize: 30,
    },
    [theme.breakpoints.down(541)]: {
      fontSize: 22,
    },
    [theme.breakpoints.down(321)]: {
      fontSize: 20,
    },
  },
  ourValues: {
    margin: "auto",
  },

  header: {
    width: "100%",
    color: theme.palette.color.skyBlue,
    fontFamily: theme.palette.font.montserrat,
    textTransform: "uppercase",
    textAlign: "center",
    margin: "45px auto 45px auto",
    [theme.breakpoints.down(960)]: {
      fontSize: 30,
    },
    [theme.breakpoints.down(541)]: {
      fontSize: 22,
    },
    [theme.breakpoints.down(321)]: {
      fontSize: 20,
    },
  },
  toolsAndServices: {
    textAlign: "center",
    margin: "auto",
    justifyContent: "center",
    [theme.breakpoints.down(600)]: {
      marginTop: 170,
    },
    [theme.breakpoints.down(376)]: {
      marginTop: 100,
    },
    [theme.breakpoints.down(321)]: {
      marginTop: 80,
    },
  },
  toolsText: {
    margin: "auto",
  },

  ourApplications: {
    backgroundColor: theme.palette.color.white,
    paddingBottom: 60,
  },

  mediaWrapper: {
    margin: "auto",
  },

  modal: {
    display: "flex",
    paddingTop: 150,
    justifyContent: "center",
    backgroundColor: "rgba(192, 191, 189, 0.103)",
    [theme.breakpoints.down(415)]: {
      paddingTop: 120,
    },
    [theme.breakpoints.down(541)]: {
      paddingTop: 115,
    },
    [theme.breakpoints.down(321)]: {
      paddingTop: 100,
    },
  },
  modalWrapper: {
    backgroundColor: theme.palette.color.darkBlue,
  },
});

export default styles;
