import React, { useRef, useState } from "react";

import { Box, ButtonBase, Menu, MenuItem, makeStyles } from "@material-ui/core";

import { Typography } from "../../components";

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 200,
    marginLeft: -32,
  },
  typography: {
    backgroundColor: theme.palette.color.lighterGrey,
    width: 90,
    height: 20,
    paddingTop: 5,

    "&:hover": {
      backgroundColor: theme.palette.color.lightGrey,
      color: theme.palette.color.white,
    },
    [theme.breakpoints.down(961)]: {
      margin: "auto%",
    },
  },
}));

export const DropdownMenu = ({
  onClickMostRecent,
  onClickOldest,
  onClickAlphabetic,
}) => {
  const classes = useStyles();

  const ref = useRef(null);

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Typography variant="p" color="inherit" className={classes.typography}>
          Sort By:
        </Typography>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={onClickMostRecent}>Most recent</MenuItem>
        <MenuItem onClick={onClickOldest}>Oldest</MenuItem>
        <MenuItem onClick={onClickAlphabetic}>Alphabetic</MenuItem>
      </Menu>
    </>
  );
};
