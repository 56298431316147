import React from "react";
import { usePagination } from "@material-ui/lab/Pagination";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    buttonContainer: {
      margin: "30px auto",
    },
    ul: {
      listStyle: "none",
      padding: 0,
      margin: 0,
      display: "flex",
    },
  })
);

export const Pagination = () => {
  const { items } = usePagination({
    count: 8,
  });
  const classes = useStyles();
  return (
    <nav style={{ margin: "auto" }}>
      <ul className={classes.ul}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <button
                type="button"
                style={{
                  fontWeight: selected ? "bold" : undefined,
                }}
                {...item}
              >
                {page}
              </button>
            );
          } else {
            children = (
              <button type="button" {...item}>
                {type}
              </button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </ul>
    </nav>
  );
};
