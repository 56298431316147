import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";

// styles
import styles from "./Styles";

const AnchorTag = (props) => {
  const { classes, target, url, text, textColor } = props;
  return (
    <a
      target={target}
      rel="noreferrer"
      href={url}
      className={classes.link}
      style={{ color: textColor }}
    >
      {text}
    </a>
  );
};
AnchorTag.propTypes = {
  classes: PropTypes.object.isRequired,
  target: PropTypes.string,
  url: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
};

export default withStyles(styles)(AnchorTag);
