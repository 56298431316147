const styles = (theme) => ({
  card: {
    width: "100%",
    borderRadius: 0,
    backgroundColor: "#222527", //theme.palette.primary.main,
    "&:hover": {
      // backgroundColor: theme.palette.color.thickGreen,
    },
  },
  media: {
    height: "100%",
  },
  logoContainer: {
    width: 50,
    height: 50,
  },
  icon: {
    width: 90,
    margin: "auto",
  },
  animationTitle: {
    color: theme.palette.color.skyBlue,
    fontSize: "8px!important",
  },
  cardMediaContainer: {
    backgroundColor: "#E0E4EC",
    width: "100%",
    height: "200px",
  },
  cardMedia: {
    width: "100%",
    height: "180px",
    position: "relative",
    margin: "auto",
    objectFit: "contain",
    backgroundSize: "contain",

    [theme.breakpoints.down(1200)]: {
      height: "110px",
    },
    [theme.breakpoints.down(960)]: {
      height: "140px",
    },

    [theme.breakpoints.down(786)]: {
      height: "120px",
    },
    [theme.breakpoints.down(723)]: {
      height: "110px",
    },
    [theme.breakpoints.down(660)]: {
      height: "100px",
    },
    [theme.breakpoints.down(620)]: {
      height: "80px",
    },

    [theme.breakpoints.down(600)]: {
      height: "275px",
      width: "100%",
    },

    [theme.breakpoints.down(415)]: {
      height: "190px",
    },
    [theme.breakpoints.down(387)]: {
      height: "180px",
    },
    [theme.breakpoints.down(375)]: {
      height: "170px",
    },
    [theme.breakpoints.down(345)]: {
      height: "160px",
    },

    [theme.breakpoints.down(281)]: {
      height: "120px",
    },
  },
  image: {
    margin: "auto",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    backgroundSize: "contain",
    objectFit: "contain",
  },
  cardButtonContainer: {
    width: "100%",
  },
  cardButtonAndEdit: {
    display: "flex",
    width: 60,
    float: "right",
  },
  cardButton: {
    fontFamily: theme.palette.font.montserrat,
    fontWeight: 900,
    paddingRight: "2%",
    paddingLeft: "2%",
    fontSize: 13,
    cursor: "pointer",
    color: theme.palette.color.red,
    marginRight: 5,
    "&:hover": {
      color: theme.palette.secondary.main,
      borderRadius: 5,
    },
  },
  editIconContainer: {
    // float: "right",
  },
  editIcon: {
    color: "red",
    height: 15,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.secondary.main,
      borderRadius: 5,
    },
  },
  link: {
    color: theme.palette.color.green,
  },
  link2: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
  },
  h1Text: {
    fontWeight: "bold",
    fontFamily: theme.palette.font.montserrat,
    color: theme.palette.color.white,
    fontSize: 15,
    textAlign: "center",
    [theme.breakpoints.down(1200)]: {
      fontSize: 13,
    },
    [theme.breakpoints.down(960)]: {
      fontSize: 15,
    },
    [theme.breakpoints.down(786)]: {
      fontSize: 13,
    },
    [theme.breakpoints.down(723)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 15,
    },
  },
  paperLabel: {
    paddingTop: "3%",
    fontFamily: theme.palette.font.montserrat,
    lineHeight: theme.palette.font.paraLineHeight,
    fontSize: 15,
    color: theme.palette.color.white,
    textAlign: "center",
    [theme.breakpoints.down(1200)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(960)]: {
      fontSize: 14,
    },

    [theme.breakpoints.down(786)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(723)]: {
      fontSize: 11,
    },

    [theme.breakpoints.down(600)]: {
      fontSize: 14,
    },
  },

  arrowForward: {
    marginBottom: -2,
  },
  contentWrapper: {
    textAlign: "left",
    height: 65,
    overflow: "hidden",
    marginTop: 20,
    backgroundColor: "#373C3F",
    [theme.breakpoints.down(1200)]: {
      height: 45,
    },
    [theme.breakpoints.down(960)]: {
      height: 65,
    },

    [theme.breakpoints.down(786)]: {
      height: 45,
    },
    [theme.breakpoints.down(723)]: {
      height: 35,
    },
    [theme.breakpoints.down(600)]: {
      height: 65,
    },
  },
});

export default styles;
