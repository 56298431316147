import React from 'react';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    margin: "auto",
    marginTop:10,
    minHeight: 225,
    borderStyle:"none",
  },
  editor: {
    margin: "auto 10px 10px 10px",
  },
}));


export default function TextEditor() {

  const classes = useStyles();
    
    return (
      <div className={classes.editor}>
        <div className={classes.tableContainer}>
          <Editor
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
        />
        </div> 
      </div>
    );
}

