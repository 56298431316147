const styles = (theme) => ({
  employeesPageWrapper: {
    flexGrow: 1,
    backgroundColor: theme.palette.color.white,
  },
  employeesContainer: {
    margin: "auto",
    minHeight: "100vh",
    [theme.breakpoints.down(960)]: {
      marginTop: 70,
    },
  },
  mediaWrapper: {
    margin: "auto",
  },
  displayEmployees: {
    backgroundColor: theme.palette.color.pinkGrey,
    margin: "30px 20px 20px 20px",

    [theme.breakpoints.down(960)]: {
      marginTop: 100,
    },
    [theme.breakpoints.down(760)]: {
      marginTop: 130,
    },
  },
  ul: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
  },
});

export default styles;
