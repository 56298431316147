import React from "react";

// MUI Stuff
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ButtonItem from "@material-ui/core/Button";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      width: "100%",
      height: 35,
      borderRadius: 10,
      textAlign: "center",
      justifyContent: "center",
      fontFamily: theme.palette.font.montserrat,
      "&:hover": {
        backgroundColor: theme.palette.color.orange,
        color: theme.palette.color.white,
      },
    },
    buttonText: { width: "100%", marginLeft: -15 },
  })
);

export const Button = (props) => {
  const { children, type, onClick, disabled, color = "primary" } = props;
  const classes = useStyles();
  return (
    <ButtonItem
      type={type}
      variant="contained"
      color={color}
      className={classes.button}
      onClick={onClick}
      disabled={disabled}
    >
      <p className={classes.buttonText}>{children}</p>
    </ButtonItem>
  );
};
