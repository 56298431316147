const styles = (theme) => ({
  tabItem: {
    // backgroundColor: "red",
  },
  headerCentered: {
    color: theme.palette.color.thickGreen,
    fontFamily: theme.palette.font.montserrat,
    margin: "auto auto 30px auto",
    textAlign: "center",
    width: "90%",
  },
});

export default styles;
