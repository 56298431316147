import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import { usePagination } from "@material-ui/lab/Pagination";

// Components
import PageHeader from "../../components/PageHeader/PageHeader";
import BranchesCard from "../../components/Branch/Branch";
import BranchDetails from "../../components/BranchDetails/BranchDetails";
import Popup from "../../components/Modals/Modal";
import QueueIcon from "@material-ui/icons/Queue";

// styles
import styles from "./Styles";

// Database
import branchDB from "../../utils/dbBranch";

const Branches = (props) => {
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState("");
  const [filterBranches, setFilterBranches] = React.useState([]);
  const [inputSearch, setInputSearch] = React.useState("");

  const handleOpen = (id) => {
    const application = branchDB.applications.find(
      (app) => app.branchId === id
    );
    setOpen(true);
    setItem(application);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setFilterBranches(
      branchDB.applications.filter((data) =>
        data.name.toLowerCase().includes(inputSearch.toLowerCase())
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, branchDB.applications]);

  const { items } = usePagination({
    count: 8,
  });

  let history = useHistory();
  const { classes } = props;
  return (
    <Grid container className={classes.branchPageWrapper}>
      <Grid
        container
        item
        xs={12}
        sm={12}
        className={classes.branchesContainer}
      >
        <PageHeader
          header="Branches Management"
          onChange={(e) => setInputSearch(e.target.value)}
          value={inputSearch}
          placeholder="Search branch"
          tip="Add a branch"
          icon={<QueueIcon />}
          onClick={() => history.push("/branches/add")}
        />

        <Grid
          container
          item
          xs={12}
          sm={12}
          className={classes.displayBranches}
        >
          <Grid container className={classes.insight}>
            <Grid
              container
              spacing={3}
              item
              sm={12}
              xs={12}
              className={classes.mediaWrapper}
            >
              {filterBranches.map((branch, index) => (
                <BranchesCard
                  branchLogo={branch.image}
                  branchName={branch.name}
                  branchAddress={branch.address}
                  readMore="More"
                  key={index}
                  onClick={() => handleOpen(branch.branchId)}
                />
              ))}
            </Grid>
          </Grid>

          <Popup
            details={<BranchDetails branch={item} onClickClose={handleClose} />}
            handleClose={handleClose}
            open={open}
            onClose={handleClose}
          />

          <nav style={{ margin: "auto" }}>
            <ul className={classes.ul}>
              {items.map(({ page, type, selected, ...item }, index) => {
                let children = null;

                if (type === "start-ellipsis" || type === "end-ellipsis") {
                  children = "…";
                } else if (type === "page") {
                  children = (
                    <button
                      type="button"
                      style={{ fontWeight: selected ? "bold" : undefined }}
                      {...item}
                    >
                      {page}
                    </button>
                  );
                } else {
                  children = (
                    <button type="button" {...item}>
                      {type}
                    </button>
                  );
                }
                return <li key={index}>{children}</li>;
              })}
            </ul>
          </nav>
        </Grid>
      </Grid>
    </Grid>
  );
};
Branches.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Branches);
