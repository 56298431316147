const styles = (theme) => ({
  profileContainer: {
    marginTop: 12,
    textAlign: "center",
  },
  fullName: {
    fontWeight: 900,
    fontSize: 17,
  },
});

export default styles;
