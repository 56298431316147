// MUI stuff
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { Typography } from "..";
import { makeStyles } from "@material-ui/core/styles";
import ShortTextField from "../TextField/ShortTextField";
import TextFieldDate from "../TextField/TextFieldDate";
import { Button } from "..";

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: theme.palette.font.montserrat,
    height: 20,
    backgroundColor: theme.palette.color.lighterGrey,
    fontSize: 12,
    fontWeight: "bold",
    width: "100%",
    paddingLeft: 10,
  },
  leftContainer: {
    borderRight: `1px solid ${theme.palette.color.lighterGrey}`,
  },
  formContainer: {
    border: `1px solid ${theme.palette.color.lighterGrey}`,
    margin: "auto 10px 10px 10px",
    minHeight: 225,
  },
  rightFormContainer: {
    border: `1px solid ${theme.palette.color.lighterGrey}`,
    margin: "auto 10px 10px 10px",
    minHeight: 225,
  },
  formSubContainer: {
    marginTop: 20,
    marginBottom: 15,
    [theme.breakpoints.down(541)]: {
      paddingLeft: 5,
      paddingRight: 5,
    },
    [theme.breakpoints.down(321)]: {
      paddingLeft: 7,
      paddingRight: 7,
    },
    [theme.breakpoints.down(281)]: {
      paddingLeft: 9,
      paddingRight: 9,
    },
  },
  formContainerPartBottom: {
    border: `1px solid ${theme.palette.color.lighterGrey}`,
    margin: "auto 10px 10px 10px",
    minHeight: 195,
  },

  section: {
    marginLeft: 20,
    fontSize: 11,
    fontFamily: theme.palette.font.montserrat,
    backgroundColor: theme.palette.color.simpleWhite,
    paddingLeft: 5,
    paddingRight: 5,
    color: theme.palette.color.orange,
  },
  button: {
    width: 200,
    margin: "10px auto 10px auto",
  },
}));

export const ProfileForm = ({ header }) => {
  const classes = useStyles();
  return (
    <Paper elevation={0}>
      <Grid item xs={12} sm={12} className={classes.header}>
        <Typography variant="p">{header}</Typography>
      </Grid>
      <Grid container item xs={12} sm={12} className={classes.form}>
        <Grid item xs={12} sm={6} className={classes.leftContainer}>
          <Typography variant="p" className={classes.section}>
            Identification
          </Typography>
          <Paper elevation={0} className={classes.formContainer}>
            <Grid container spacing={3} className={classes.formSubContainer}>
              <ShortTextField
                autofocus
                name="company"
                label="Company :"
                type="text"
                // icon={<BusinessIcon />}
              />
              <ShortTextField
                name="code"
                label="Code :"
                type="text"
                // icon={<BusinessIcon />}
              />
              <ShortTextField
                name="nom"
                label="Nom :"
                type="text"
                // icon={<BusinessIcon />}
              />
              <ShortTextField
                name="email"
                label="E-mail :"
                type="text"
                // icon={<BusinessIcon />}
              />
              <TextFieldDate label="Issued Date" />
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.leftContainer}>
          <Typography variant="p" className={classes.section}>
            Memo
          </Typography>
          <Paper elevation={0} className={classes.formContainer}>
            <Grid container spacing={3} className={classes.formSubContainer}>
              <ShortTextField
                name="comments"
                label="Comments :"
                type="text"
                rows={3}
                multiline
              />
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.leftContainer}>
          <Typography variant="p" className={classes.section}>
            Main address
          </Typography>
          <Paper elevation={0} className={classes.formContainerPartBottom}>
            <Grid container spacing={3} className={classes.formSubContainer}>
              <ShortTextField
                autofocus
                name="idNumber"
                label="ID Number :"
                type="text"
                // icon={<BusinessIcon />}
              />
              <ShortTextField
                name="street"
                label="Street :"
                type="text"
                // icon={<BusinessIcon />}
              />
              <ShortTextField
                name="country"
                label="Country :"
                type="text"
                // icon={<BusinessIcon />}
              />
              <ShortTextField
                name="province"
                label="Province :"
                type="text"
                // icon={<BusinessIcon />}
              />
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.leftContainer}>
          <Typography variant="p" className={classes.section}>
            Main phone
          </Typography>
          <Paper elevation={0} className={classes.formContainerPartBottom}>
            <Grid container spacing={3} className={classes.formSubContainer}>
              <ShortTextField
                autofocus
                name="type"
                label="Type :"
                type="text"
                // icon={<BusinessIcon />}
              />
              <ShortTextField
                name="number"
                label="Number :"
                type="text"
                // icon={<BusinessIcon />}
              />
              <ShortTextField
                name="extension"
                label="Extension :"
                type="text"
                // icon={<BusinessIcon />}
              />
            </Grid>
          </Paper>
        </Grid>
        <div className={classes.button}>
          {" "}
          <Button>Save</Button>
        </div>
      </Grid>
    </Paper>
  );
};
