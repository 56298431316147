import React, { useEffect } from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// Components
import PageHeader from "../../components/PageHeader/PageHeader";
import UsersCard from "../../components/UserCard/UserCard";
import EmployeeDetails from "../../components/EmployeeDetails/EmployeeDetails";
import Popup from "../../components/Modals/Modal";
import Tabs from "../../components/Tabs/Tabs";
import TabHeader from "../../components/TabHeader/TabHeader";
import EditEmployees from "../editEmployees/editEmployees";
import {
  SimpleCard,
  AddEmployeeForm,
  Pagination,
  DropdownMenu,
} from "../../components";
// styles
import styles from "./Styles";

// Database
import usersDB from "../../utils/dbUsers";

const Employees = (props) => {
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState("");
  const [editEmployee, setEditEmployee] = React.useState("");
  const [openEdit, setOpenEdit] = React.useState(false);
  const [filterEmployees, setFilterEmployees] = React.useState([]);
  const [inputSearch, setInputSearch] = React.useState("");

  const handleOpen = (id) => {
    const user = usersDB.users.find((user) => user.userId === id);
    setOpen(true);
    setItem(user);
  };
  const handleEditEmployee = (id) => {
    const user = usersDB.users.find((user) => user.userId === id);
    setOpenEdit(true);
    setEditEmployee(user);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const matches = useMediaQuery("(max-width:415px)");

  useEffect(() => {
    setFilterEmployees(
      usersDB.users.filter(
        (data) =>
          data.firstName.toLowerCase().includes(inputSearch.toLowerCase()) ||
          data.lastName.toLowerCase().includes(inputSearch.toLowerCase())
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, usersDB.users]);

  const { classes } = props;
  return (
    <Grid container className={classes.employeesPageWrapper}>
      <Grid
        container
        item
        xs={12}
        sm={12}
        className={classes.employeesContainer}
      >
        <PageHeader
          header="Employees Management"
          onChange={(e) => setInputSearch(e.target.value)}
          value={inputSearch}
          placeholder="Search First name"
          tip="Add an employee"
        />
        <Tabs
          tip="View employees"
          tabLabel="View employees"
          pageTab0={
            <Grid
              container
              item
              xs={12}
              sm={12}
              className={classes.usersContainer}
            >
              <DropdownMenu />
              <Grid
                container
                spacing={matches ? 1 : 3}
                item
                sm={12}
                xs={12}
                className={classes.mediaWrapper}
              >
                {filterEmployees.map((employee, index) => (
                  <UsersCard
                    userLogo={employee.image}
                    userName={employee.firstName + " " + employee.lastName}
                    position={employee.position}
                    branch={employee.branch}
                    key={index}
                    onClickMore={() => handleOpen(employee.userId)}
                    onClickEdit={() => handleEditEmployee(employee.userId)}
                  />
                ))}
              </Grid>
              <Popup
                details={
                  <EditEmployees
                    employee={editEmployee}
                    onClickClose={handleCloseEdit}
                    closeImg
                    addOrEditEmployee="edit"
                  />
                }
                handleClose={handleCloseEdit}
                open={openEdit}
                onClose={handleCloseEdit}
              />
              <Popup
                details={
                  <EmployeeDetails employee={item} onClickClose={handleClose} />
                }
                handleClose={handleClose}
                open={open}
                onClose={handleClose}
              />
              <Pagination />
            </Grid>
          }
          tip1="Add an employee"
          tabLabel1="Add employee"
          pageTab1={
            <Grid container item sx={12} sm={12} style={{ margin: "auto" }}>
              <TabHeader header="Fill Out This Form" />
              <Grid
                container
                item
                xs={12}
                sm={12}
                className={classes.addUserFormContainer}
              >
                <SimpleCard>
                  <EditEmployees
                    employee={editEmployee}
                    onClickClose={handleCloseEdit}
                    addOrEditEmployee="add"
                  />
                </SimpleCard>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
};
Employees.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Employees);
