const styles = (theme) => ({
  card: {
    width: "100%",
  },
  media: {
    height: "100%",
  },
  logoContainer: {
    width: 50,
    height: 50,
  },
  icon: {
    width: 90,
    margin: "auto",
  },
  animationTitle: {
    color: theme.palette.color.skyBlue,
    fontSize: "8px!important",
  },
  cardMediaContainer: {
    backgroundColor: "#E0E4EC",
    width: "100%",
    height: "280px",
  },
  cardMedia: {
    width: "100%",
    height: "240px",
    position: "relative",
    margin: "auto",
    objectFit: "contain",
    backgroundSize: "contain",
    // [theme.breakpoints.down(1679)]: {
    //   height: "230px",
    // },
    // [theme.breakpoints.down(1662)]: {
    //   height: "210px",
    // },
    // [theme.breakpoints.down(1515)]: {
    //   height: "195px",
    // },
    // [theme.breakpoints.down(1416)]: {
    //   height: "185px",
    // },
    // [theme.breakpoints.down(1343)]: {
    //   height: "170px",
    // },
    [theme.breakpoints.down(1248)]: {
      height: "160px",
    },
    [theme.breakpoints.down(960)]: {
      height: "240px",
    },
    [theme.breakpoints.down(938)]: {
      height: "220px",
    },
    [theme.breakpoints.down(860)]: {
      height: "200px",
    },
    [theme.breakpoints.down(786)]: {
      height: "180px",
    },
    [theme.breakpoints.down(723)]: {
      height: "160px",
    },
    [theme.breakpoints.down(600)]: {
      height: "275px",
      width: "99%",
    },

    [theme.breakpoints.down(415)]: {
      height: "190px",
    },
    [theme.breakpoints.down(387)]: {
      height: "180px",
    },
    [theme.breakpoints.down(375)]: {
      height: "170px",
    },
    [theme.breakpoints.down(345)]: {
      height: "160px",
    },

    [theme.breakpoints.down(281)]: {
      height: "120px",
    },
  },
  image: {
    margin: "auto",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    backgroundSize: "contain",
    objectFit: "contain",
  },
  cardButtonContainer: {
    width: "100%",
  },
  cardButton: {
    fontFamily: theme.palette.font.montserrat,
    fontWeight: 900,
    paddingRight: "2%",
    paddingLeft: "2%",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.color.white,
    float: "right",
    marginRight: 10,
    "&:hover": {
      backgroundColor: theme.palette.color.skyBlue,
    },
  },
  link: {
    color: theme.palette.color.green,
  },
  link2: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
  },
  h1Text: {
    fontWeight: "bold",
    fontFamily: theme.palette.font.montserrat,
    color: "#000000",
    [theme.breakpoints.down(1776)]: {
      fontSize: 26,
    },
    [theme.breakpoints.down(1490)]: {
      fontSize: 18,
    },
  },
  paperLabel: {
    paddingTop: "3%",
    fontFamily: theme.palette.font.montserrat,
    lineHeight: theme.palette.font.paraLineHeight,
    fontSize: 15,
    [theme.breakpoints.down(1045)]: {
      fontSize: 14,
    },
    [theme.breakpoints.down(719)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
    },
    [theme.breakpoints.down(415)]: {
      fontSize: 14,
    },
    [theme.breakpoints.down(321)]: {
      fontSize: 12,
    },
  },

  arrowForward: {
    marginBottom: -2,
  },
  contentWrapper: {
    textAlign: "left",
    height: 200,
    overflow: "hidden",
  },
});

export default styles;
