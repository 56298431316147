import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  border: {
    fontFamily: theme.palette.font.montserrat,
    height: 20,
    backgroundColor: theme.palette.color.lemonGreen,
    fontSize: 12,
    fontWeight: "bold",
    width: "100%",
    borderStyle: "groove",
    borderColor: theme.palette.color.lemonGreen,
    color: "black",
  },
  border1: {
    fontFamily: theme.palette.font.montserrat,
    height: 20,
    backgroundColor: "#FAF4F3",
    fontSize: 12,
    fontWeight: "bold",
    width: "100%",
    borderStyle: "groove",
    borderColor: "grey",
    color: "black",
    marginTop: 2,
  },
  container: {
    width: "99%",
    margin: "auto",
    marginTop: 10,
  },
  text: {
    marginTop: -8,
    textAlign: "center",
  },
}));

const Availabilities = (props) => {

    const classes = useStyles();

    const{day} = props;

    return (
      <div>
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12} className={classes.border}>
            <div className={classes.text}>{day}</div>
          </Grid>

          <Grid item xs={12} className={classes.border1}>
            <div className={classes.text}>0,00</div>
          </Grid>

        </Grid>
      </div>
    );
};

export default Availabilities;