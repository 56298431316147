import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";

// styles
import styles from "./Styles";

const ProfileImage = (props) => {
  const { classes, userImage, fullName } = props;
  return (
    <div className={classes.profileContainer}>
      <div edge="start">
        <img src={userImage} alt="logo" />
      </div>

      <p className={classes.fullName}>{fullName}</p>
    </div>
  );
};
ProfileImage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProfileImage);
