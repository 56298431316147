import React from "react";

// React Slick Stuff
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// MUI Stuff
import Grid from "@material-ui/core/Grid";

//Static Images
import HeaderSlider from "./HeaderSlider";

import DB from "../../utils/dbSchema";

const BoardSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    pauseOnHover: true,
  };

  return (
    <Grid item sm={12} xs={12}>
      <Slider {...settings}>
        {DB.slideImages.map((user, index) => (
          <Grid>
            <HeaderSlider key={index} image={user.image} />
          </Grid>
        ))}
      </Slider>
    </Grid>
  );
};

export default BoardSlider;
