// eslint-disable-next-line import/no-anonymous-default-export
export default {
  palette: {
    primary: {
      main: "#0B2239",
    },
    secondary: {
      main: "#ec8f56",
    },
    color: {
      contrastWhite: "#cccccc",
      skyBlue: "#00CCFF",
      white: "#F6FFF8",
      grey: "rgba(255, 251, 251, 0.5)",
      lightGrey: "#bdbcbc",
      thickGreen: "#004346",
      pinkGrey: "#F5F5F5",
      red: "#FF0000",
      lemonGreen: "#D0F4BA",
      orange: "#ec8f56",
      lighterGrey: "#e5e4e2",
      snowWhite: "#FFFFFF",
    },

    font: {
      montserrat: "Montserrat",
      fontStretch: "normal",
      paraLineHeight: 1.7,
      letterSpacing: "0.23px",
      textAlign: "center",
    },
  },
  typography: {
    useNextVariants: true,
  },
};
