import React from "react";
import PropTypes from "prop-types";

// MUI Stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";

// styles
import styles from "./Styles";

// Images
import logo from "../../images/logo.svg";

const Blog = (props) => {
  const { classes, appLogo, key } = props;
  return (
    <Grid item xs={12} sm={6} md={4} lg={4} className={classes.cardWrapper}>
      <Card className={classes.card} key={key}>
        <CardActionArea>
          <div className="project-1">
            <div className={classes.cardMedia}>
              <CardMedia className={classes.media} image={appLogo} />
            </div>

            <div className="prj-1-content prj-two">
              <div style={{ margin: "auto", width: "100%", height: "300px" }}>
                <div className={classes.icon}>
                  <img
                    src={logo}
                    alt="logo"
                    style={{
                      width: "100%",
                      height: "100%",
                      paddingTop: 10,
                      objectFit: "contain",
                      backgroundSize: "contain",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

Blog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Blog);
