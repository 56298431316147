import React from "react";

// MUI Stuff
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Avatar from "@material-ui/core/Avatar";

const HeaderSlider = ({ classes, image, key }) => {
  return (
    <CardActionArea>
      <Grid container item xs={12} sm={12}>
        <Grid item xs={12} sm={14} className={classes.avatarMainWrapper}>
          <CardMedia>
            <Avatar className={classes.sliderImageWrapper}>
              <div edge="start" className={classes.sliderContainer}>
                <img src={image} alt="logo" className={classes.sliderImage} />
              </div>
            </Avatar>
          </CardMedia>
        </Grid>
      </Grid>
    </CardActionArea>
  );
};

const styles = (theme) => ({
  cardItemsContainer: {
    maxWidth: "100%",
    minHeight: "100%",
    borderBottom: "none",
  },
  sliderImageWrapper: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    height: "100%",
    alignItem: "center",
    borderRadius: 0,
    paddingTop: 10,
  },

  avatarMainWrapper: {
    paddingBottom: 40,
  },

  sliderContainer: {
    width: "98%",
    height: "87vh",
  },

  sliderImage: {
    width: "100%",
    height: "100%",
  },
});

export default withStyles(styles)(HeaderSlider);
