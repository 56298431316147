import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import QueueIcon from "@material-ui/icons/Queue";

// styles
import styles from "./Styles";
// Components
import { Tooltip } from "../../components";

// Images
import DealerImg from "../../images/dealerLogo.svg";

const languages = [
  {
    value: "english",
    label: "English",
  },
  {
    value: "french",
    label: "French",
  },
  {
    value: "spanish",
    label: "Spanish",
  },
];

const PageHeader = (props) => {
  const [language, setLanguage] = React.useState("english");

  const onLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  const selectedOption = (val) => {
    return val.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ));
  };

  const {
    classes,
    header,
    icon = "",
    onChange,
    value,
    placeholder,
    tip,
    onClick,
    display,
  } = props;
  return (
    <Grid container item xs={12} sm={11} className={classes.pageBar}>
      <Grid item xs={4} sm={4} className={classes.languageContainer}>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            native
            value={language}
            onChange={onLanguageChange}
            inputProps={{
              name: "language",
              id: "outlined-language-native-simple",
            }}
            className={classes.selectField}
          >
            {selectedOption(languages)}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={11}
        sm={11}
        md={4}
        lg={4}
        className={classes.logoAndTitleContainer}
      >
        <div className={classes.pageHeaderContainer}>
          <div style={{ textAlign: "center" }}>
            <img src={DealerImg} alt="Dealer" style={{ width: 190 }} />
          </div>
          <div className={classes.headerAndAddIcon}>
            <div>
              <h3 className={classes.pageTitle}>{header}</h3>
            </div>
            <div
              onClick={onClick}
              className={classes.addIcon}
              style={{ display }}
            >
              <Tooltip tip={tip} placement="top">
                <QueueIcon />
              </Tooltip>
            </div>
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={11}
        sm={11}
        md={4}
        lg={4}
        className={classes.searchContainer}
      >
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon style={{ color: "#ec8f56" }} />
          </div>
          <InputBase
            placeholder={placeholder}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            value={value}
            onChange={onChange}
          />
        </div>
      </Grid>
    </Grid>
  );
};
PageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  tip: PropTypes.string.isRequired,
};

export default withStyles(styles)(PageHeader);
