// /* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// styles
import styles from "./Styles";

// Components
import PageHeader from "../../components/PageHeader/PageHeader";
import TabHeader from "../../components/TabHeader/TabHeader";
import UsersCard from "../../components/UserCard/UserCard";
import UserDetails from "../../components/UserDetails/UserDetails";
import Popup from "../../components/Modals/Modal";
import {
  SimpleCard,
  AddUserForm,
  Pagination,
  DropdownMenu,
} from "../../components";
import Tabs from "../../components/Tabs/Tabs";
import EditUser from "../editUser/editUser";

// Database
import usersDB from "../../utils/dbUsers";

const Users = (props) => {
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState("");
  const [editUser, setEditUser] = React.useState("");
  const [openEdit, setOpenEdit] = React.useState(false);
  const [filterUsers, setFilterUsers] = React.useState([]);
  const [inputSearch, setInputSearch] = React.useState("");

  const handleOpen = (id) => {
    const user = usersDB.users.find((user) => user.userId === id);
    setOpen(true);
    setItem(user);
  };
  const handleEditUser = (id) => {
    const user = usersDB.users.find((user) => user.userId === id);
    setOpenEdit(true);
    setEditUser(user);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const matches = useMediaQuery("(max-width:415px)");

  useEffect(() => {
    setFilterUsers(
      usersDB.users.filter(
        (data) =>
          data.firstName.toLowerCase().includes(inputSearch.toLowerCase()) ||
          data.lastName.toLowerCase().includes(inputSearch.toLowerCase())
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, usersDB.users]);

  const { classes } = props;
  return (
    <Grid container className={classes.usersPageWrapper}>
      <Grid container item xs={12} sm={12} className={classes.usersContainer}>
        <PageHeader
          header="User Management"
          onChange={(e) => setInputSearch(e.target.value)}
          value={inputSearch}
          placeholder="Search name"
          tip="Add a user"
        />

        <Tabs
          tip="View users"
          tabLabel="Users"
          pageTab0={
            <Grid
              container
              item
              xs={12}
              sm={12}
              className={classes.usersContainer}
            >
              <DropdownMenu />
              <Grid
                container
                spacing={matches ? 1 : 3}
                item
                sm={12}
                xs={12}
                className={classes.mediaWrapper}
              >
                {filterUsers.map((user, index) => (
                  <UsersCard
                    userLogo={user.image}
                    userName={user.firstName + " " + user.lastName}
                    position={user.position}
                    branch={user.branch}
                    key={index}
                    onClickMore={() => handleOpen(user.userId)}
                    onClickEdit={() => handleEditUser(user.userId)}
                  />
                ))}
              </Grid>
              <Popup
                details={
                  <EditUser
                    user={editUser}
                    onClickClose={handleCloseEdit}
                    closeImg
                    addOrEditUser="edit"
                  />
                }
                handleClose={handleCloseEdit}
                open={openEdit}
                onClose={handleCloseEdit}
              />
              <Popup
                details={<UserDetails user={item} onClickClose={handleClose} />}
                handleClose={handleClose}
                open={open}
                onClose={handleClose}
              />
              <Pagination />
            </Grid>
          }
          tip1="Add user"
          tabLabel1="Add a user"
          pageTab1={
            <Grid container item sx={12} sm={12} style={{ margin: "auto" }}>
              <TabHeader header="Fill Out This Form" />
              <Grid
                container
                item
                xs={12}
                sm={12}
                className={classes.addUserFormContainer}
              >
                <SimpleCard>
                  <EditUser user={editUser} onClickClose={handleCloseEdit} addOrEditUser="add"/>
                </SimpleCard>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
};
Users.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Users);
