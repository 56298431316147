import React, { useEffect } from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import { usePagination } from "@material-ui/lab/Pagination";

// Components
import PageHeader from "../../components/PageHeader/PageHeader";
import VehicleCard from "../../components/Vehicle/Vehicle";
import BranchDetails from "../../components/BranchDetails/BranchDetails";
import Popup from "../../components/Modals/Modal";

// styles
import styles from "./Styles";

// Database
import vehicleDB from "../../utils/dbVehicles";

const Vehicles = (props) => {
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState("");
  const [filterVehicles, setFilterVehicles] = React.useState([]);
  const [inputSearch, setInputSearch] = React.useState("");

  const handleOpen = (id) => {
    const application = vehicleDB.vehicles.find((app) => app.vehicleId === id);
    setOpen(true);
    setItem(application);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setFilterVehicles(
      vehicleDB.vehicles.filter((data) =>
        data.brand.toLowerCase().includes(inputSearch.toLowerCase())
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, vehicleDB.vehicles]);

  const { items } = usePagination({
    count: 8,
  });

  console.log("items:", items);
  const { classes } = props;
  return (
    <Grid container className={classes.branchPageWrapper}>
      <Grid
        container
        item
        xs={12}
        sm={12}
        className={classes.branchesContainer}
      >
        <PageHeader
          header="Vehicles Management"
          onChange={(e) => setInputSearch(e.target.value)}
          value={inputSearch}
          placeholder="Search vehicle"
          display="none"
        />

        <Grid
          container
          item
          xs={12}
          sm={12}
          className={classes.displayVehicles}
        >
          <Grid container className={classes.insight}>
            <Grid
              container
              spacing={3}
              item
              sm={12}
              xs={12}
              className={classes.mediaWrapper}
            >
              {filterVehicles.map((vehicle, index) => (
                <VehicleCard
                  vehicleImage={vehicle.image}
                  vehicleBrand={vehicle.brand}
                  vehicleYear={vehicle.year}
                  vehicleDate={vehicle.date}
                  readMore="More"
                  key={index}
                  onClick={() => handleOpen(vehicle.vehicleId)}
                />
              ))}
            </Grid>
          </Grid>

          <Popup
            details={
              <BranchDetails vehicle={item} onClickClose={handleClose} />
            }
            handleClose={handleClose}
            open={open}
            onClose={handleClose}
          />

          <nav style={{ margin: "auto" }}>
            <ul className={classes.ul}>
              {items.map(({ page, type, selected, ...item }, index) => {
                let children = null;

                if (type === "start-ellipsis" || type === "end-ellipsis") {
                  children = "…";
                } else if (type === "page") {
                  children = (
                    <button
                      type="button"
                      style={{ fontWeight: selected ? "bold" : undefined }}
                      {...item}
                    >
                      {page}
                    </button>
                  );
                } else {
                  children = (
                    <button type="button" {...item}>
                      {type}
                    </button>
                  );
                }
                return <li key={index}>{children}</li>;
              })}
            </ul>
          </nav>
        </Grid>
      </Grid>
    </Grid>
  );
};
Vehicles.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Vehicles);
