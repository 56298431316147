const styles = (theme) => ({
  navigationWrapper: {
    flexGrow: 1,
  },
  footerWrapper: {
    backgroundColor: theme.palette.primary.main,
    paddingLeft: "5.4%",
    paddingRight: "5.4%",
  },
  footerContainer: {
    margin: "auto",
  },
  footerText: {
    color: theme.palette.color.grey,
    fontFamily: theme.palette.font.montserrat,
    textAlign: "center",
    fontSize: 14,
    lineHeight: theme.palette.font.lineHeight,
  },

  socialMediaContainer: {
    display: "flex",
    marginBottom: 10,
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      margin: "-20px auto 20px auto",
    },
  },
  logo: {
    width: 30,
    height: 30,
    [theme.breakpoints.down(376)]: {
      width: 25,
      height: 25,
    },
    [theme.breakpoints.down(281)]: {
      width: 20,
      height: 20,
    },
  },
  socialMedia: {
    marginTop: 20,
    cursor: "pointer",
  },
});

export default styles;
