const styles = (theme) => ({
  userFullDetailsWrapper: {
    backgroundColor: "#F8F8FF",
    color: theme.palette.color.yellow,
    width: "100%",
    height: 520,
    overflowY: "scroll",
    margin: "auto auto 35px auto",
    border: `1px solid ${theme.palette.color.skyBlue}`,
    borderRadius: 10,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.color.white,
    },
    [theme.breakpoints.down(415)]: {
      height: 500,
    },
    [theme.breakpoints.down(541)]: {
      height: 550,
    },
    [theme.breakpoints.down(415)]: {
      height: 610,
    },
    [theme.breakpoints.down(376)]: {
      height: 500,
    },
    [theme.breakpoints.down(321)]: {
      height: 430,
    },
  },
  title: {
    fontFamily: theme.palette.font.montserrat,
    color: theme.palette.secondary.main,
    fontSize: 20,
    textTransform: "uppercase",
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.down(415)]: {
      fontSize: "100%",
    },
  },
  titleDots: {
    color: theme.palette.color.yellow,
    bottom: -5,
    position: "relative",
  },

  description: {
    fontFamily: theme.palette.font.montserrat,
    margin: "10px 20px 10px 20px",
  },

  text: {
    lineHeight: theme.palette.font.paraLineHeight,
    fontSize: 15,
    [theme.breakpoints.down(1045)]: {
      fontSize: 14,
    },
    [theme.breakpoints.down(719)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
    },
    [theme.breakpoints.down(415)]: {
      fontSize: 14,
    },
    [theme.breakpoints.down(321)]: {
      fontSize: 12,
    },
  },

  starsContainer: {
    width: "100%",
    marginBottom: 10,
  },
  starsSmallContainer: {
    display: "flex",
    width: "20%",
    float: "right",
    justifyContent: "space-between",
    marginRight: 10,
  },
  logo: {
    float: "right",
    marginRight: 15,
    marginTop: -5,
    cursor: "pointer",
  },
});

export default styles;
