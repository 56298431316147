const styles = (theme) => ({
  label: {
    paddingLeft: 25,
    marginTop: 5,
    fontFamily: theme.palette.font.montserrat,
    fontSize: 13,
  },
  labelSimpleCheckbox: {
    paddingLeft: 25,
    marginTop: 5,
    fontFamily: theme.palette.font.montserrat,
    fontSize: 13,
    [theme.breakpoints.only("sm")]: {
      marginLeft: 20,
    },
  },

  checkbox: {
    color: theme.palette.color.skyBlue,
    marginTop: -5,
    marginLeft: 5,
    [theme.breakpoints.only("sm")]: {
      marginLeft: -10,
    },
    
  },

  simpleChecbox: {
    color: theme.palette.color.skyBlue,
    marginTop: -5,
    marginLeft: 10,
  },
});

export default styles;
