const styles = (theme) => ({
  branchPageWrapper: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.color.white,
  },

  moreVehicleContainer: {
    margin: "auto",
    minHeight: "100vh",
    [theme.breakpoints.down(600)]: {
      marginTop: 70,
    },
  },

  displayVehicles: {
    //backgroundColor: "grey", //theme.palette.color.white,
    margin: "30px 20px 20px 20px",
    [theme.breakpoints.down(960)]: {
      marginTop: 90,
    },
    [theme.breakpoints.down(760)]: {
      marginTop: 130,
    },
  },
  image: {
    margin: "40px auto 20px auto",
    width: "75%",
    objectFit: "contain",
    backgroundSize: "contain",
  },
  paper: {
    width: "100%",
    marginRight: 0,
  },

  mediaWrapper: {
    margin: "auto",
  },
});

export default styles;
