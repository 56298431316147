import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// MUI Stuff
import withStyles from "@material-ui/core/styles/withStyles";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";

// styles
import styles from "./Styles";

// Components
import { Typography, Tooltip, Card } from "../../components";

export const UserCard = (props) => {
  const {
    classes,
    userLogo,
    userName,
    position,
    branch,
    onClickMore,
    onClickEdit,
    key,
  } = props;

  let history = useHistory();
  
  return (
    <Grid item xs={6} sm={3} md={3} lg={2} className={classes.cardWrapper}>
      <Card className={classes.card} key={key}>
        <div>
          <div className={classes.moreContainer}>
            <Tooltip tip="View details" placement="top">
              <MoreVertIcon
                className={classes.editIcon}
                onClick={onClickMore}
              />
            </Tooltip>
          </div>
          <div className={classes.cardMedia}>
            <CardMedia className={classes.media} image={userLogo} />
            <div className={classes.editIconContainer}>
              <Tooltip tip={"Edit" + " " + userName} placement="top">
                <EditOutlinedIcon
                  className={classes.editIcon}
                  onClick={onClickEdit}
                />
              </Tooltip>
            </div>
          </div>
        </div>

        <CardContent className={classes.contentWrapper}>
          <Typography color="inherit" variant="h4" className={classes.h1Text}>
            {userName}
          </Typography>
          <Typography color="inherit" className={classes.paperLabel}>
            {position}
          </Typography>
          <Typography color="inherit" className={classes.paperLabelBranch}>
            {branch}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

UserCard.propTypes = {
  classes: PropTypes.object.isRequired,
  readMore: PropTypes.string,
  firstName: PropTypes.string,
  position: PropTypes.string.isRequired,
  userLogo: PropTypes.string.isRequired,
};

export default withStyles(styles)(UserCard);
