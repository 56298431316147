import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Paper from "@material-ui/core/Paper";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

// Components
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  Button,
  Typography,
} from "../../components";

import { ProfileForm } from "../../components/UserForm/ProfileForm";
import {GroupForm }from "../../components/UserForm/GroupForm";
import { OptionsForm } from "../../components/UserForm/OptionsForm";

// styles
import styles from "./Styles";

import CloseImg from "../../images/close.svg";

const categoryList = ["Category 1", "Category 2", "Category 3"];
const categoryList1 = ["General options", "Options 1", "Options 2"];

function getSteps() {
  return ["Profile", "Groups", "Options"];
}


const BranchForms = (props) => {

   const { classes, user, onClickClose, closeImg, addOrEditUser } = props;

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <ProfileForm
            header="Profile Information"
            editUser={user}
            addOrEditUser={addOrEditUser}
          />
        );
      case 1:
        return (
          <GroupForm
            header="Group Information"
            label="Category :"
            list={categoryList}
            defaultValue={addOrEditUser=="edit" ?"Category 1" : ""}
          />
        );
      case 2:
        return (
          <OptionsForm
            header="Options Information"
            label="Category :"
            list={categoryList1}
            defaultValue={addOrEditUser == "edit" ? "General options" : ""}
          />
        );
      default:
        return <ProfileForm header="Profile Information" editUser={user} />;
    }
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
   
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    
    <Grid container className={classes.editBranchPageWrapper}>
      <Grid
        container
        item
        xs={12}
        sm={12}
        className={classes.editBranchPageContainer}
      >
        {closeImg &&  <Grid item xs={12} sm={12}>
            <div className={classes.header}>
              <div onClick={onClickClose} >
                <img src={CloseImg} alt="photo" className={classes.logo} />
              </div>
            </div>
        </Grid>}

        <Grid item xs={12} sm={12} className={classes.tabContainer}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Grid>
            {activeStep === steps.length ? (
              <Grid>
                <Paper
                  elevation={0}
                  className={classes.paper}
                  style={{ textAlign: "center" }}
                >
                  <Typography>All steps completed</Typography>
                  <div style={{ width: 100, margin: "20px auto auto auto" }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                  </div>
                </Paper>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} className={classes.tabContainer}>
                <Typography>{getStepContent(activeStep)}</Typography>
                <div style={{ display: "flex" }}>
                  <Box mx={1} my={2} style={{ width: 100 }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                  </Box>
                  <Box my={2} style={{ width: 100 }}>
                    <Button
                      className="ml-4"
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </Box>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(BranchForms);
