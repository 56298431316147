import React, { useState } from "react";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";

import styles from "./Styles";

const SimpleCheckbox = (props) => {
  const { classes, label, ifChecked } = props;

  const [checkedIds, setCheckedIds] = useState([]);
  const [isChecked, setIsChecked] = useState(
    ifChecked == "true" ? true : false
  );

  function handleCheck(event) {
    const checkedId = event.target.value;

    setIsChecked(!isChecked);

    if (isChecked) {
      setCheckedIds([...checkedIds, checkedId]);
    } else {
      setCheckedIds(checkedIds.filter((id) => id !== checkedId));
    }
  }
  return (
    <>
      <Grid container>
        <Grid item xs={1} sm={1} md={1}>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.simpleChecbox}
                value=""
                onChange={handleCheck}
                checked={isChecked}
              />
            }
          />
        </Grid>

        <Grid item xs={8} sm={8} md={8} className={classes.labelSimpleCheckbox}>
          {label}
        </Grid>
      </Grid>
    </>
  );
};

SimpleCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleCheckbox);
