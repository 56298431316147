import React from "react";
import { Card } from "@material-ui/core";

export const SimpleCard = ({ children, subtitle, icon }) => {
  return (
    <Card elevation={6} style={{ width: "100%", paddingTop: 30 }}>
      {subtitle && <div className="card-subtitle mb-4">{subtitle}</div>}
      {children}
    </Card>
  );
};
