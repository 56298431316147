import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

// Components
import { Divider } from "../../components";

// styles
import styles from "./Styles";

const height = 32;
const TextFieldInput = (props) => {
  const { classes, label, onChange, value } = props;
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={11} md={5} lg={5} className={classes.label}>
          {label}
        </Grid>
        <Grid item xs={12} sm={11} md={7} lg={7}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="mui-pickers-date"
              className={classes.textField}
              inputVariant="outlined"
              margin="none"
              size="small"
              autoOk={true}
              format="MMM dd, yyyy"
              fullWidth
              value={value}
              onChange={onChange}
              InputProps={{
                style: {
                  height,
                  padding: "0 18px 2px 0",
                },
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <div className={classes.dividerWrapper}>
        <Divider />
      </div>
    </>
  );
};
TextFieldInput.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextFieldInput);
