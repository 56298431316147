const styles = (theme) => ({
  employeeFullDetailsWrapper: {
    backgroundColor: theme.palette.color.white,
    color: theme.palette.color.yellow,
    width: "100%",
    height: 520,
    overflowY: "scroll",
    margin: "auto auto 35px auto",
    border: `1px solid ${theme.palette.color.skyBlue}`,
    borderRadius: 10,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.color.white,
    },
    [theme.breakpoints.down(415)]: {
      height: 500,
    },
    [theme.breakpoints.down(541)]: {
      height: 550,
    },
    [theme.breakpoints.down(415)]: {
      height: 610,
    },
    [theme.breakpoints.down(376)]: {
      height: 500,
    },
    [theme.breakpoints.down(321)]: {
      height: 430,
    },
  },
  header: {
    marginTop: 15,
  },
  title: {
    marginTop: 15,
    fontFamily: theme.palette.font.montserrat,
    color: theme.palette.secondary.main,
    fontSize: 20,
    textTransform: "uppercase",
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.down(415)]: {
      fontSize: "100%",
    },
  },
  employeeImage: {
    margin: "auto",
    textAlign: "center",
  },
  logo: {
    float: "right",
    marginRight: 15,
    marginTop: -5,
    cursor: "pointer",
  },
  paperUserDetails: {
    width: "80%",
    margin: "30px auto auto auto",
  },
  detailsLeft: {
    fontFamily: theme.palette.font.montserrat,
  },
  detailsRight: {},
});

export default styles;
