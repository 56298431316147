const styles = (theme) => ({
  editBranchPageWrapper: {
    flexGrow: 1,
    backgroundColor: theme.palette.color.white,
  },
  editBranchPageContainer: {
    margin: "auto",
    minHeight: "100vh",
  },
  formContainer: {
    backgroundColor: theme.palette.color.pinkGrey,
    margin: "30px 20px 20px 20px",
    border: `1px solid ${theme.palette.color.skyBlue}`,
    [theme.breakpoints.down(960)]: {
      marginTop: 90,
    },
    [theme.breakpoints.down(760)]: {
      marginTop: 130,
    },
    [theme.breakpoints.down(415)]: {
      marginTop: 130,
      width: "90%",
    },
    [theme.breakpoints.down(321)]: {
      marginLeft: 15,
    },
  },

  tabContainer: {
    backgroundColor: theme.palette.color.pinkGrey,
    margin: "30px 20px 20px 20px",
    border: `1px solid ${theme.palette.color.skyBlue}`,
    [theme.breakpoints.down(415)]: {
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 10,
    },
  },
  paper: {
    minHeight: 300,
  },
  step: {
    [theme.breakpoints.down(541)]: {
      marginLeft: -15,
    },
    [theme.breakpoints.down(415)]: {
      marginLeft: -10,
    },
    [theme.breakpoints.down(321)]: {
      marginLeft: -11,
    },
    [theme.breakpoints.down(281)]: {
      marginLeft: -15,
    },
  },
  twoButtonsContainer: {
    display: "flex",
    [theme.breakpoints.down(541)]: {
      justifyContent: "center",
    },
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.color.skyBlue}`,
  },
  tabPageContainer: {
    minHeight: "72vh",
    backgroundColor: theme.palette.color.lemonGreen,
    fontFamily: theme.palette.font.montserrat,
  },
  tab: {
    backgroundColor: theme.palette.color.lemonGreen,
    color: theme.palette.color.thickGreen,
    fontWeight: 900,
  },
});

export default styles;
