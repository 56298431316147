// /* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import QueueIcon from "@material-ui/icons/Queue";
import GroupIcon from "@material-ui/icons/Group";

// styles
import styles from "./Styles";

// Components
import { Typography, Tooltip } from "../../components";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

const LinkTab = (props) => {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
};

const TabContainer = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { classes, pageTab0, pageTab1, tip, tip1, tabLabel, tabLabel1 } = props;
  return (
    <Grid container item xs={12} sm={12} className={classes.tabContainer}>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="nav tabs example"
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
            className={classes.tabItem}
          >
            <LinkTab
              label={
                <div className={classes.labelTitle}>
                  {tabLabel}
                  <div className={classes.iconContainer}>
                    <Tooltip tip={tip} placement="top">
                      <div
                        size="small"
                        color="inherit"
                        className={classes.cardButton}
                      >
                        <GroupIcon />
                      </div>
                    </Tooltip>
                  </div>{" "}
                </div>
              }
              href=""
              {...a11yProps(0)}
              className={classes.tab}
            />
            <LinkTab
              label={
                <div className={classes.labelTitle}>
                  {tabLabel1}
                  <div className={classes.iconContainer}>
                    <Tooltip tip={tip1} placement="top">
                      <div
                        size="small"
                        color="inherit"
                        className={classes.cardButton}
                      >
                        <QueueIcon />
                      </div>
                    </Tooltip>
                  </div>{" "}
                </div>
              }
              href=""
              {...a11yProps(1)}
              className={classes.tab}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} className={classes.tabPageContainer}>
          {pageTab0}
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabPageContainer}>
          {pageTab1}
        </TabPanel>
      </div>
    </Grid>
  );
};
TabContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default withStyles(styles)(TabContainer);
