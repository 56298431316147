import React from "react";

// Images
import HomeIcon from "../../../images/home.svg";
import EmployeeIcon from "../../../images/employee.svg";
import UserIcon from "../../../images/user.svg";
import BranchIcon from "../../../images/branch.svg";
import CarIcon from "../../../images/car.png";
import KeyIcon from "../../../images/key2.png";
import GroupsIcon from "../../../images/groups.png";
import TreatmentIcon from "../../../images/settings.png";
import CompanyIcon from "../../../images/organization.png";
import StackIcon from "../../../images/stack.png";
import PlannerIcon from "../../../images/calendar.png";

const logoFunc = (logo) => (
  <div edge="start">
    <img src={logo} alt="logo" />
  </div>
);

export const SidebarData = [
  {
    title: "Home",
    path: "/home",
    icon: logoFunc(HomeIcon),
    cName: "nav-text",
  },
  {
    title: "License",
    path: "/license",
    icon: logoFunc(KeyIcon),
    cName: "nav-text",
  },
  {
    title: "Vehicles Management",
    path: "/vehicles",
    icon: logoFunc(CarIcon),
    cName: "nav-text",
  },
  {
    title: "Employees Management",
    path: "/employees",
    icon: logoFunc(EmployeeIcon),
    cName: "nav-text",
  },
  {
    title: "Users Management",
    path: "/users",
    icon: logoFunc(UserIcon),
    cName: "nav-text",
  },
  {
    title: "Branches Management",
    path: "/branches",
    icon: logoFunc(BranchIcon),
    cName: "nav-text",
  },
  {
    title: "Tasks Management",
    path: "/tasks",
    icon: logoFunc(StackIcon),
    cName: "nav-text",
  },
  {
    title: "Companies Management",
    path: "/companies",
    icon: logoFunc(CompanyIcon),
    cName: "nav-text",
  },
  {
    title: "Groups Management",
    path: "/group",
    icon: logoFunc(GroupsIcon),
    cName: "nav-text",
  },
  {
    title: "Planner",
    path: "/Planner",
    icon: logoFunc(PlannerIcon),
    cName: "nav-text",
  },
  {
    title: "Treatment",
    path: "/treatment",
    icon: logoFunc(TreatmentIcon),
    cName: "nav-text",
  },
];
