const styles = (theme) => ({
  paper: {
    width: "96%",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },

  header: {
    color: theme.palette.color.skyBlue,
    fontFamily: theme.palette.font.montserrat,
    textTransform: "uppercase",
    [theme.breakpoints.down(960)]: {
      fontSize: 30,
    },
    [theme.breakpoints.down(541)]: {
      fontSize: 22,
    },
    [theme.breakpoints.down(321)]: {
      fontSize: 20,
    },
  },
  text: {
    color: theme.palette.color.lightBlack,
    lineHeight: theme.palette.font.paraLineHeight,
    [theme.breakpoints.down(1045)]: {
      fontSize: 14,
    },
    [theme.breakpoints.down(719)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
    },
    [theme.breakpoints.down(415)]: {
      fontSize: 14,
    },
    [theme.breakpoints.down(321)]: {
      fontSize: 12,
    },
  },
});

export default styles;
