import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import Slider from "../../components/Slider/Slider";

// styles
import styles from "./Styles";

const LogoContainer = (props) => {
  const { classes } = props;
  return (
    <Grid container item xs={12} sm={12} className={classes.logoGrid}>
      <Grid item sm={12} xs={12} className={classes.imageSliderWrapper}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.imageSliderSection}
        >
          <Slider />
        </Grid>
      </Grid>
    </Grid>
  );
};
LogoContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LogoContainer);
