const styles = (theme) => ({
  checkboxAndPara: {
    display: "flex",
  },
  text: {
    height: 5,
    marginTop: 0,
    color: theme.palette.color.lightBlack,
    lineHeight: theme.palette.font.paraLineHeight,
    [theme.breakpoints.down(1045)]: {
      fontSize: 14,
    },
    [theme.breakpoints.down(719)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
    },
    [theme.breakpoints.down(415)]: {
      fontSize: 14,
    },
    [theme.breakpoints.down(321)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(281)]: {
      fontSize: 11,
    },
  },
  logoWrapper: {
    marginRight: 20,
    [theme.breakpoints.down(281)]: {
      marginRight: 5,
    },
  },
  textContainer: {
    padding: 0,
  },
});

export default styles;
