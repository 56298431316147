import React from "react";

// MUI Stuff
import { Card, Grid, MenuItem } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import BusinessIcon from "@material-ui/icons/Business";
import HomeIcon from "@material-ui/icons/Home";
import PublicIcon from "@material-ui/icons/Public";
import GridOnIcon from "@material-ui/icons/GridOn";
import PersonIcon from "@material-ui/icons/Person";

// Components
import { Button } from "..";
import TextField from "../TextField/TextField";
import ShortTextField from "../TextField/ShortTextField";

const phoneTypeList = ["Portable", "Principle", "Other"];
const emailTypeList = ["Office", "Personal", "Other"];

const useStyles = makeStyles(() =>
  createStyles({
    buttonContainer: {
      margin: "30px auto",
    },
  })
);

export const AddVehicleForm = () => {
  const classes = useStyles();

  return (
    <Card>
      <div>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="description"
              label="Description :"
              type="text"
              rows={3}
              multiline
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="brand "
              label="Brand :"
              select
              fullWidth
              list={phoneTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
            <ShortTextField
              name="model "
              label="Model :"
              select
              fullWidth
              list={phoneTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
          </Grid>
        </Grid>

        {/* Town and Code */}
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="year "
              label="Year :"
              select
              fullWidth
              list={phoneTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="version "
              label="Version :"
              select
              fullWidth
              list={phoneTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
          </Grid>
        </Grid>

        {/* Province and Code */}
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="employer"
              label="Employer :"
              type="text"
              icon={<HomeIcon />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="mileage"
              label="Mileage :"
              type="text"
              icon={<GridOnIcon />}
            />
          </Grid>
        </Grid>

        {/* Country and Code */}
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="stockNumber"
              label="Stock Number :"
              type="text"
              icon={<PublicIcon />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="IdNumber"
              label="ID Number :"
              type="text"
              icon={<GridOnIcon />}
            />
          </Grid>
        </Grid>

        {/* Phone */}
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="state "
              label="State :"
              select
              fullWidth
              list={phoneTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="type"
              label="Type :"
              select
              fullWidth
              list={phoneTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
          </Grid>
        </Grid>

        {/* Email */}
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="innerColor "
              label="Inner Color :"
              select
              fullWidth
              list={phoneTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="outerColor "
              label="Outer Color :"
              select
              fullWidth
              list={phoneTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
          </Grid>
        </Grid>

        {/* Email */}
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="transmission "
              label="Transmission :"
              select
              fullWidth
              list={phoneTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="fuelType "
              label="Fuel Type :"
              select
              fullWidth
              list={phoneTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
          </Grid>
        </Grid>

        {/* Doors */}
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="numberDoor "
              label="Number Doors :"
              select
              fullWidth
              list={phoneTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="numberPassengers"
              label="# Passengers:"
              select
              fullWidth
              list={phoneTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
          </Grid>
        </Grid>

        {/* Doors */}
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="numberCylinders "
              label="# Cylinders :"
              select
              fullWidth
              list={phoneTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="motorSkills"
              label="Motor skills:"
              select
              fullWidth
              list={phoneTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
          </Grid>
        </Grid>

        {/* Doors */}
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="airConditioning "
              label="Air Condition :"
              select
              fullWidth
              list={phoneTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="motorSkills"
              label="Motor skills:"
              select
              fullWidth
              list={phoneTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
          </Grid>
        </Grid>

        {/* Country and Code */}
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="options"
              label="Options :"
              type="text"
              icon={<PublicIcon />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="note"
              label="Note :"
              type="text"
              icon={<GridOnIcon />}
            />
          </Grid>
        </Grid>

        {/* Country and Code */}
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="localization"
              label="Localization:"
              select
              fullWidth
              list={phoneTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="Date"
              label="Date :"
              type="text"
              icon={<GridOnIcon />}
            />
          </Grid>
        </Grid>

        <Grid item xs={11} sm={8} className={classes.buttonContainer}>
          <Button type="submit">Register Vehicle</Button>
        </Grid>
      </div>
    </Card>
  );
};
