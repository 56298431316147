const styles = (theme) => ({
  navigationWrapper: {
    flexGrow: 1,
  },
  navbarContainer: {
    fontFamily: theme.palette.font.montserrat,
    marginLeft: 40,
    marginRight: 40,
    [theme.breakpoints.down(860)]: {
      marginLeft: "2%",
      marginRight: "-2%",
    },
    [theme.breakpoints.down(782)]: {
      marginRight: 0,
    },
    [theme.breakpoints.down(737)]: {
      marginLeft: "1%",
    },
    [theme.breakpoints.down(716)]: {
      marginLeft: 0,
    },

    [theme.breakpoints.down(600)]: {
      marginLeft: 5,
      marginRight: 20,
    },
    [theme.breakpoints.down(415)]: {
      marginLeft: 2,
      marginRight: 5,
    },
    [theme.breakpoints.down(376)]: {
      marginLeft: 0,
      marginRight: 5,
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up(600)]: {
      display: "flex",
      width: 300,
      justifyContent: "space-between",
      [theme.breakpoints.down(1409)]: {
        // width: 500,
      },
      [theme.breakpoints.down(1285)]: {
        // width: 400,
      },
      [theme.breakpoints.down(1156)]: {
        width: 280,
      },
      [theme.breakpoints.down(1121)]: {
        width: 240,
      },
      [theme.breakpoints.down(939)]: {
        width: 220,
      },

      [theme.breakpoints.down(875)]: {
        width: 200,
      },
      [theme.breakpoints.down(860)]: {
        marginRight: "-40%",
      },
    },
    [theme.breakpoints.down(688)]: {
      marginRight: "-35%",
    },
    [theme.breakpoints.down(647)]: {
      marginRight: "-33%",
    },
    [theme.breakpoints.down(638)]: {
      marginRight: "-31%",
    },
  },
  emptyHorizontalSpaceDesktop: {
    flexGrow: 1,
    [theme.breakpoints.down(600)]: {
      display: "none",
    },
  },

  emptyHorizontalSpace: {
    flexGrow: 1,
    [theme.breakpoints.up(860)]: {
      display: "none",
      backgroundColor: "red",
    },
  },
  sectionMobile: {
    display: "flex",
    float: "right",
    [theme.breakpoints.up(600)]: {
      display: "none",
    },
  },
  logo: {
    width: "150px",
    height: "40px",
    marginBottom: -7,
  },

  mobileMenuColor: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  list: {
    padding: 0,
  },
  mobileSingleMenuItem: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },

  signinAndSignupBtn: {
    fontFamily: theme.palette.font.montserrat,
    width: 200,
    backgroundColor: theme.palette.secondary.main,
    fontSize: "1em",
    color: theme.palette.primary.main,
    marginRight: 20,
    "&:hover": {
      backgroundColor: theme.palette.color.skyBlue,
      color: theme.palette.color.white,
    },

    [theme.breakpoints.down(1120)]: {
      fontSize: 10,
      width: 125,
    },
    [theme.breakpoints.down(1076)]: {
      fontSize: 11,
      width: 130,
    },
    [theme.breakpoints.down(1031)]: {
      marginLeft: 30,
    },
    [theme.breakpoints.down(875)]: {
      fontSize: 9,
      width: 120,
    },
    [theme.breakpoints.down(860)]: {
      display: "none",
    },
  },
  contactUsBtnMobile: {
    display: "none",

    [theme.breakpoints.down(860)]: {
      display: "block",
      width: "100%",
      backgroundColor: theme.palette.secondary.main,
      fontSize: "1em",
      color: theme.palette.primary.main,
      fontFamily: theme.palette.font.montserrat,
      "&:hover": {
        backgroundColor: theme.palette.color.skyBlue,
        color: theme.palette.color.white,
      },
    },
  },
});

export default styles;
