const styles = (theme) => ({
  editBranchPageWrapper: {
    flexGrow: 1,
    backgroundColor: theme.palette.color.white,
    height: 500,
    overflowY: "scroll",
    [theme.breakpoints.down(1280)]: {
      height: 470,
    },
  },

  editBranchPageContainer: {
    margin: "auto",
    minHeight: "100vh",
    [theme.breakpoints.down(960)]: {
      marginTop: 70,
    },
  },
  tabContainer: {
    backgroundColor: theme.palette.color.pinkGrey,
    margin: "30px 20px 20px 20px",
    border: `1px solid ${theme.palette.color.skyBlue}`,
    // backgroundColor: "red",
    // [theme.breakpoints.down(960)]: {
    //   marginTop: 90,
    // },
    // [theme.breakpoints.down(760)]: {
    //   marginTop: 130,
    // },
  },
  paper: {
    minHeight: 300,
  },

  header: {
    marginTop: 15,
  },

  logo: {
    float: "right",
    marginRight: 15,
    marginTop: -5,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginTop: -75,
    },
  },

  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.color.skyBlue}`,
  },
  tabPageContainer: {
    minHeight: "72vh",
    backgroundColor: theme.palette.color.lemonGreen,
    fontFamily: theme.palette.font.montserrat,
  },
  tab: {
    backgroundColor: theme.palette.color.lemonGreen,
    color: theme.palette.color.thickGreen,
    fontWeight: 900,
  },
  tabItem: {
    // backgroundColor: "red",
  },
});

export default styles;
