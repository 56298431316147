import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// MUI
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";

// Components
import AnchorTag from "../../AnchorTag/AnchorTag";

// Static images
import logo from "../../../images/logo.svg";

// styles
import styles from "./Styles";

const Navbar = (props) => {
  const [state, setState] = useState({
    achorEl: false,
    MobileMoreAnchorEl: false,
  });
  const [alignment, setAlignment] = React.useState("left");
  const isMobileMenuOpen = Boolean(state.mobileMoreAnchorEl);
  const mobileMenuId = "primary-search-account-menu-mobile";

  const handleMobileMenuClose = () => {
    setState({
      mobileMoreAnchorEl: null,
    });
  };

  const handleMobileMenuOpen = (event) => {
    setState({
      mobileMoreAnchorEl: event.currentTarget,
    });
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const { classes } = props;
  // eslint-disable-next-line no-unused-vars
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  // mobile
  const renderMobileMenu = (
    <Menu
      anchorEl={state.mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      classes={{ list: classes.list }}
    >
      <MenuItem className={classes.mobileMenuColor} onClick={handleClose}>
        <Button
          color="inherit"
          component={Link}
          to="/login"
          activeClassName="navbar__link--active"
          className="navbar__link spacingRight"
        >
          Log In
        </Button>
      </MenuItem>

      <MenuItem className={classes.mobileMenuColor} onClick={handleClose}>
        <Button
          variant="contained"
          color="primary"
          className={classes.contactUsBtnMobile}
        >
          <AnchorTag url="/contact-us" text="Contact Us" textColor="#F6FFF8" />
        </Button>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.navigationWrapper}>
      <AppBar position="fixed">
        <Toolbar className={classes.navbarContainer}>
          <IconButton edge="start" component={Link} to="/">
            <div edge="start" className={classes.logoWrapper}>
              <img src={logo} alt="logo" className={classes.logo} />
            </div>
          </IconButton>

          <div
            className={classes.sectionDesktop}
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <div className="button">
              <NavLink
                activeClassName="navbar__link--active"
                className="navbar__link"
                to="/login"
              >
                Connexion
              </NavLink>
            </div>
          </div>

          <div className="button">
            <Button
              variant="contained"
              color="primary"
              className={classes.contackUsBtn}
              component={Link}
              // to="/contact-us"
            >
              <NavLink
                color="inherit"
                to="/contact-us"
                activeClassName="navbar__link--active"
                className="navbar__link"
              >
                Inscription
              </NavLink>
              {/* <AnchorTag text="Nous joindre" textColor="#F6FFF8" /> */}
            </Button>
          </div>
          <div className={classes.emptyHorizontalSpace} />
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
};

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Navbar);
