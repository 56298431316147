import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

// Components
import { Divider } from "../../components";

// styles
import styles from "./Styles";

const TextFieldDate = (props) => {
  const {
    classes,
    name,
    type,
    label,
    defaultValue,
    rows,
    inputRef,
    error,
    multiline,
    select,
    list,
    icon,
  } = props;
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={11} md={5} lg={5} className={classes.label}>
          {label}
        </Grid>
        <Grid item xs={12} sm={11} md={7} lg={7}>
          <TextField
            name={name}
            type={type}
            inputRef={inputRef}
            error={error}
            defaultValue={defaultValue}
            className={classes.textField}
            fullWidth
            size="small"
            multiline={multiline}
            rows={rows}
            select={select}
            variant="outlined"
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              endAdornment:
                select || name === "address1" || name === "address2"
                  ? ""
                  : icon,
              classes: {
                root: rows ? "" : classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
            }}
          >
            {select && list}
          </TextField>
        </Grid>
      </Grid>
      <div className={classes.dividerWrapper}>
        <Divider />
      </div>
    </>
  );
};
TextFieldDate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextFieldDate);
