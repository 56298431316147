import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import EmailIcon from "@material-ui/icons/Email";

// styles
import styles from "./Styles";
import Theme from "../../utils/theme";

// Images
import AppIcon from "../../images/logo.svg";

// Components
import SimpleTextField from "../../components/TextField/SimpleTextField";
import { Typography, Button } from "../../components";

const defaultValues = {
  userName: "",
  password: "",
  licenseNumber: "",
};
const Login = (props) => {
  const { register, handleSubmit, errors, reset } = useForm({
    //   resolver: yupResolver(signinSchema),
  });

  const [checkedIds, setCheckedIds] = useState([]);

  function handleCheck(event) {
    const checkedId = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setCheckedIds([...checkedIds, checkedId]);
    } else {
      setCheckedIds(checkedIds.filter((id) => id !== checkedId));
    }
  }
  let history = useHistory();
  const handleSignIn = async (data) => {
    console.log("Signin data:", data);
    try {
      history.push("/branches");
      // reset(defaultValues);
    } catch (e) {
      console.log(e);
    }
  };
  const { classes } = props;
  return (
    <Grid container className={classes.loginPageWrapper}>
      <Grid item xs={12} sm={12} className={classes.loginPageContainer}>
        <Grid item sm />
        <Grid item sx={12} sm={6} md={5} lg={4} className={classes.middle}>
          <div style={{ textAlign: "center" }}>
            <img src={AppIcon} alt="logo-portrait" className={classes.image} />
          </div>
          <Typography variant="h2" className={classes.pageTitle}>
            Connexion
          </Typography>
          <Grid xs={11} sm={11} className={classes.formContainer}>
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(handleSignIn)}
            >
              <Grid item xs={12} sm={12} className={classes.textFieldContainer}>
                <SimpleTextField
                  name="userName"
                  type="text"
                  label="User Name"
                  {...register}
                  icon={<EmailIcon />}
                />
              </Grid>

              <Grid item xs={12} sm={12} className={classes.textFieldContainer}>
                <SimpleTextField
                  name="password"
                  label="Password"
                  type="password"
                  {...register}
                />
              </Grid>

              <Grid item xs={12} sm={12} className={classes.textFieldContainer}>
                <SimpleTextField
                  name="Password"
                  label="License Number"
                  type="password"
                />
              </Grid>

              <div className={classes.textForm}>
                <FormControlLabel
                  label={
                    <p className={classes.rememberMe}>Se souvient de moi</p>
                  }
                  control={
                    <Checkbox
                      style={{
                        color: Theme.palette.color.skyBlue,
                      }}
                      value=""
                      onChange={handleCheck}
                    />
                  }
                />
              </div>
              <br />
              <div className={classes.buttonWrapper}>
                <Button type="submit">Se Connecter</Button>
              </div>

              <br />
              <small className={classes.haveAnAccount}>
                Je n'ai pas de compte?{" "}
                <Link to="/contact-us">
                  <span className={classes.login}>Sign-up {">"} </span>
                </Link>
              </small>
              <br />
              <small className={classes.forgetEmail}>
                Mot de passe oublié?{" "}
                <Link to="/reset-password">
                  <span className={classes.login}>cliquer ici {">"} </span>
                </Link>
              </small>
            </form>
            <div className={classes.blankSpace}></div>
          </Grid>
        </Grid>

        <Grid item sm />
      </Grid>
    </Grid>
  );
};
Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
