import React from "react";

// MUI Stuff
import { Card, Grid, MenuItem } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import BusinessIcon from "@material-ui/icons/Business";
import HomeIcon from "@material-ui/icons/Home";
import PublicIcon from "@material-ui/icons/Public";
import GridOnIcon from "@material-ui/icons/GridOn";
import PersonIcon from "@material-ui/icons/Person";

// Components
import { Button, Divider } from "..";
import TextField from "../TextField/TextField";
import ShortTextField from "../TextField/ShortTextField";

const phoneTypeList = ["Portable", "Principle", "Other"];
const emailTypeList = ["Office", "Personal", "Other"];

const useStyles = makeStyles(() =>
  createStyles({
    buttonContainer: {
      margin: "30px auto",
    },
  })
);

export const AddUserForm = () => {
  const classes = useStyles();

  return (
    <Card>
      <div>
        {/* Full name */}
        <TextField
          name="fullName"
          label="First name :"
          type="text"
          autofocus
          icon={<PersonIcon />}
        />
        <TextField
          name="lastName"
          label="Last name :"
          type="text"
          icon={<PersonIcon />}
        />

        {/* Address 1 and 2 */}
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="address1"
              label="Address 1 :"
              type="text"
              rows={3}
              multiline
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="address2"
              label="Address 2 :"
              type="text"
              multiline
              rows={3}
            />
          </Grid>
        </Grid>

        {/* Town and Code */}
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="town"
              label="Town :"
              type="text"
              icon={<BusinessIcon />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="code"
              label="Code :"
              type="text"
              icon={<GridOnIcon />}
            />
          </Grid>
        </Grid>

        {/* Province and Code */}
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="province"
              label="Province :"
              type="text"
              icon={<HomeIcon />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="code"
              label="Code :"
              type="text"
              icon={<GridOnIcon />}
            />
          </Grid>
        </Grid>

        {/* Country and Code */}
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="Country"
              label="country :"
              type="text"
              icon={<PublicIcon />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="code"
              label="Code :"
              type="text"
              icon={<GridOnIcon />}
            />
          </Grid>
        </Grid>

        {/* Phone */}
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="phone"
              label="Phone :"
              type="text"
              icon={<PhoneIcon />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="type"
              label="Type :"
              select
              fullWidth
              list={phoneTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
          </Grid>
        </Grid>

        {/* Email */}
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="email"
              label="Email :"
              type="text"
              icon={<MailOutlineIcon />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ShortTextField
              name="type"
              label="Type :"
              select
              fullWidth
              list={emailTypeList.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            />
          </Grid>
        </Grid>

        <Grid item xs={11} sm={8} className={classes.buttonContainer}>
          <Button type="submit">Add User</Button>
        </Grid>
      </div>
    </Card>
  );
};
