/* eslint-disable-line no-useless-computed-key */
import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

// styles
import styles from "./Styles";

// Static images
import Twitter from "../../../images/twitter.svg";
import Tictoc from "../../../images/tictoc.svg";
import Instagram from "../../../images/instagram.svg";
import Facebook from "../../../images/facebook.svg";

const Footer = (props) => {
  const { classes } = props;

  return (
    <>
      <Grid container className={classes.footerWrapper}>
        <Grid item xs={12} sm={12} md={4} lg={4} />

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className={classes.footerContainer}
        >
          <div className={classes.footerText}>
            <p>©EJSOFT 2021. Tous les droits sont réservés.</p>
          </div>
        </Grid>

        <Grid container item xs={12} sm={12} md={4} lg={4}>
          <Grid item xs={12} sm={12} md={6} lg={6} />
          <Grid
            item
            xs={6}
            sm={4}
            md={6}
            lg={6}
            className={classes.socialMediaContainer}
          >
            <div className={classes.socialMedia}>
              <img src={Instagram} alt="Instagram" className={classes.logo} />
            </div>
            <div className={classes.socialMedia}>
              <img src={Tictoc} alt="Instagram" className={classes.logo} />
            </div>
            <div className={classes.socialMedia}>
              <img src={Twitter} alt="Instagram" className={classes.logo} />
            </div>
            <div className={classes.socialMedia}>
              <img src={Facebook} alt="Instagram" className={classes.logo} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
