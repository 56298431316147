/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

// MUI Stuff
import { Card, Grid, MenuItem } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import BusinessIcon from "@material-ui/icons/Business";
import HomeIcon from "@material-ui/icons/Home";
import PublicIcon from "@material-ui/icons/Public";
import GridOnIcon from "@material-ui/icons/GridOn";
import PersonIcon from "@material-ui/icons/Person";
import Paper from "@material-ui/core/Paper";

// Components
import { Button, Divider } from "../../components";
import TextField from "../TextField/TextField";
import ShortTextField from "../TextField/ShortTextField";
import TabHeader from "../../components/TabHeader/TabHeader";

// Images
import CloseImg from "../../images/close.svg";

const phoneTypeList = ["Portable", "Principle", "Other"];
const emailTypeList = ["Office", "Personal", "Other"];

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonContainer: {
      margin: "30px auto",
    },
    userEditWrapper: {
      color: theme.palette.color.yellow,
      width: "100%",
      height: 568,
      overflowY: "scroll",
      margin: "auto auto 35px auto",
      border: `1px solid ${theme.palette.color.skyBlue}`,
      borderRadius: 10,
      [theme.breakpoints.down(415)]: {
        height: 500,
      },
      [theme.breakpoints.down(541)]: {
        height: 550,
      },
      [theme.breakpoints.down(415)]: {
        height: 610,
      },
      [theme.breakpoints.down(376)]: {
        height: 500,
      },
      [theme.breakpoints.down(321)]: {
        height: 430,
      },
    },
    modalContainer: {
      marginBottom: 50,
    },
    logo: {
      float: "right",
      marginRight: 15,
      marginTop: -15,
      cursor: "pointer",
    },
    userImage: {
      margin: "20px auto auto 20px",
    },
    headerContainer: {
      marginTop: -60,
      [theme.breakpoints.down(601)]: {
        marginLeft: 15,
      },
      [theme.breakpoints.down(281)]: {
        marginLeft: 75,
      },
    },
  })
);

export const EditUser = ({ user, key, onClickClose }) => {
  const classes = useStyles();

  return (
    <Grid item sx={12} sm={12} key={key}>
      <Paper
        variant="outlined"
        elevation={0}
        className={classes.userEditWrapper}
      >
        <div className={classes.modalContainer}>
          <div className={classes.userImage}>
            <img
              src={user.image}
              alt={user.firstName + " " + "photo"}
              className={classes.logoD}
            />
          </div>
          <div className={classes.header}>
            <div onClick={onClickClose} className={classes.logoContainer}>
              <img src={CloseImg} alt="photo" className={classes.logo} />
            </div>
            <div className={classes.headerContainer}>
              <TabHeader header="Edit User" />
            </div>
          </div>
        </div>

        <form
          noValidate
          autoComplete="off"
          // onSubmit={handleSubmit(handleSubscription)}
          // className={classes.form}
        >
          <Card>
            <div>
              {/* Full name */}
              <TextField
                name="fullName"
                label="Full name :"
                type="text"
                defaultValue={user.firstName}
                icon={<PersonIcon />}
              />
              <TextField
                name="lastName"
                label="last name :"
                type="text"
                defaultValue={user.lastName}
                icon={<PersonIcon />}
              />

              {/* Address 1 and 2 */}
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <ShortTextField
                    name="address1"
                    label="Address 1 :"
                    defaultValue={user.address1}
                    type="text"
                    rows={3}
                    multiline
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <ShortTextField
                    name="address2"
                    label="Address 2 :"
                    defaultValue={user.address2}
                    type="text"
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>

              {/* Town and Code */}
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <ShortTextField
                    name="town"
                    label="Town :"
                    defaultValue={user.town}
                    type="text"
                    icon={<BusinessIcon />}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <ShortTextField
                    name="codeTown"
                    label="Code :"
                    defaultValue={user.codeTown}
                    type="text"
                    icon={<GridOnIcon />}
                  />
                </Grid>
              </Grid>

              {/* Province and Code */}
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <ShortTextField
                    name="province"
                    label="Province :"
                    defaultValue={user.province}
                    type="text"
                    icon={<HomeIcon />}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <ShortTextField
                    name="codeProvince"
                    label="Code :"
                    defaultValue={user.codeProvince}
                    type="text"
                    icon={<GridOnIcon />}
                  />
                </Grid>
              </Grid>

              {/* Country and Code */}
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <ShortTextField
                    name="country"
                    label="Country :"
                    defaultValue={user.country}
                    type="text"
                    icon={<PublicIcon />}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <ShortTextField
                    name="codeCountry"
                    label="Code :"
                    defaultValue={user.codeCountry}
                    type="text"
                    icon={<GridOnIcon />}
                  />
                </Grid>
              </Grid>

              {/* Phone */}
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <ShortTextField
                    name="phone"
                    label="Phone :"
                    defaultValue={user.phone}
                    type="text"
                    icon={<PhoneIcon />}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <ShortTextField
                    name="phoneType"
                    label="Type :"
                    defaultValue={user.phoneType}
                    select
                    fullWidth
                    list={phoneTypeList.map((location) => (
                      <MenuItem key={location} value={location}>
                        {location}
                      </MenuItem>
                    ))}
                  />
                </Grid>
              </Grid>

              {/* Email */}
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <ShortTextField
                    name="email"
                    label="Email :"
                    defaultValue={user.email}
                    type="text"
                    icon={<MailOutlineIcon />}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <ShortTextField
                    name="emailType"
                    label="Type :"
                    defaultValue={user.emailType}
                    select
                    fullWidth
                    list={emailTypeList.map((location) => (
                      <MenuItem key={location} value={location}>
                        {location}
                      </MenuItem>
                    ))}
                  />
                </Grid>
              </Grid>

              <Grid item xs={11} sm={8} className={classes.buttonContainer}>
                <Button type="submit">Update User</Button>
              </Grid>
            </div>
          </Card>
        </form>
      </Paper>
    </Grid>
  );
};
