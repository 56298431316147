import React from "react";

// MUI stuff
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { Typography } from "..";
import { makeStyles } from "@material-ui/core/styles";
import ShortTextField from "../TextField/ShortTextField";
import { Button } from "..";
import { MenuItem } from "@material-ui/core";

// components
import Checkbox from "../checkbox/SimpleCheckbox";
import TextFieldDate from "../TextField/TextFieldDate";
import TextEditor from "../TextEditor/TextEditor";
import Availabilities from "./Availabilities";

const branchList = ["Car Dealer", "Web Developer", "Manager"];
const fonctionList = ["Technician", "Secretary", "Other Fonction"];

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: theme.palette.font.montserrat,
    height: 20,
    backgroundColor: theme.palette.color.lighterGrey,
    fontSize: 12,
    fontWeight: "bold",
    width: "100%",
    paddingLeft: 10,
  },
  leftContainer: {
    borderRight: `1px solid ${theme.palette.color.lighterGrey}`,
  },
  tableContainer: {
    border: `1px solid ${theme.palette.color.lighterGrey}`,
    margin: "auto 10px 10px 10px",
    minHeight: 560,
  },
  tableEditorContainer: {
    border: `1px solid ${theme.palette.color.lighterGrey}`,
    width: "90%",
    margin: "auto",
    minHeight: 520,
  },
  tableContainerPart3: {
    border: `1px solid ${theme.palette.color.lighterGrey}`,
    margin: "auto 10px 10px 10px",
    minHeight: 100,
  },
  tableContainerPart2: {
    border: `1px solid ${theme.palette.color.lighterGrey}`,
    margin: "auto 10px 10px 10px",
    minHeight: 195,
  },
  container: {
    width: "95%",
    margin: "auto",
    marginTop: 10,
    [theme.breakpoints.only("sm")]: {
      marginBottom: 15,
      marginTop: 5,
    },
    [theme.breakpoints.only("xs")]: {
      marginBottom: 15,
      marginTop: 5,
    },
  },
  section: {
    marginLeft: 20,
    fontSize: 11,
    fontFamily: theme.palette.font.montserrat,
    backgroundColor: theme.palette.color.simpleWhite,
    paddingLeft: 5,
    paddingRight: 5,
    color: theme.palette.color.orange,
  },
}));

export const ProfileForm = ({ header, employee, addOrEditEmployee }) => {
  const classes = useStyles();

  return (
    <Paper elevation={0}>
      <Grid item xs={12} sm={12} className={classes.header}>
        <Typography variant="p">{header}</Typography>
      </Grid>
      <Grid container item xs={12} sm={12} className={classes.form}>
        <Grid item xs={12} sm={6} className={classes.leftContainer}>
          <Typography variant="p" className={classes.section}>
            Identification
          </Typography>
          <Paper elevation={0} className={classes.tableContainer}>
            <Grid
              container
              spacing={3}
              style={{ marginTop: 20, marginBottom: 15 }}
              className={classes.rightContainerdd}
            >
              <ShortTextField
                name="code"
                label="Code :"
                type="text"
                defaultValue={addOrEditEmployee == "edit" ? employee.code : ""}
                // icon={<BusinessIcon />}
              />

              <ShortTextField
                name="company"
                label="Company :"
                type="text"
                defaultValue={
                  addOrEditEmployee == "edit" ? employee.company : ""
                }
                // icon={<BusinessIcon />}
              />

              <ShortTextField
                autofocus
                name="branch"
                label="Branch :"
                type="text"
                defaultValue={
                  addOrEditEmployee == "edit" ? employee.branch : ""
                }
                select
                fullWidth
                list={branchList.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
                // icon={<BusinessIcon />}
              />

              <ShortTextField
                name="firstName"
                label="First Name :"
                type="text"
                defaultValue={
                  addOrEditEmployee == "edit" ? employee.firstName : ""
                }
                // icon={<BusinessIcon />}
              />

              <ShortTextField
                name="lastName"
                label="Last Name :"
                type="text"
                defaultValue={
                  addOrEditEmployee == "edit" ? employee.lastName : ""
                }
                // icon={<BusinessIcon />}
              />

              <ShortTextField
                autofocus
                name="foction"
                label="Fonction :"
                type="text"
                defaultValue={
                  addOrEditEmployee == "edit" ? employee.description : ""
                }
                select
                fullWidth
                list={fonctionList.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
                // icon={<BusinessIcon />}
              />

              <ShortTextField
                name="email"
                label="Email :"
                type="text"
                defaultValue={addOrEditEmployee == "edit" ? employee.email : ""}
                // icon={<BusinessIcon />}
              />

              <ShortTextField
                name="cc"
                label="CC :"
                type="text"
                defaultValue={
                  addOrEditEmployee == "edit" ? employee.codeCountry : ""
                }
                // icon={<BusinessIcon />}
              />

              <ShortTextField
                name="phone"
                label="Phone :"
                type="text"
                defaultValue={addOrEditEmployee == "edit" ? employee.phone : ""}
                // icon={<BusinessIcon />}
              />

              <ShortTextField
                name="extension"
                label="Extension :"
                type="text"
                defaultValue={addOrEditEmployee == "edit" ? "Extension" : ""}
                // icon={<BusinessIcon />}
              />

              <Checkbox
                label="Active"
                ifChecked={addOrEditEmployee == "edit" ? "true" : "false"}
              />

              <TextFieldDate label="Issued Date" />
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.leftContainer}>
          <Typography variant="p" className={classes.section}>
            Memo
          </Typography>
          <Paper elevation={0} className={classes.tableContainer}>
            <Grid
              container
              spacing={3}
              style={{ marginTop: 20, marginBottom: 15 }}
              className={classes.rightContainerdd}
            >
              <div className={classes.tableEditorContainer}>
                <TextEditor />
              </div>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={12} className={classes.leftContainer}>
          <Typography variant="p" className={classes.section}>
            Availabilities
          </Typography>
          <Paper elevation={0} className={classes.tableContainerPart3}>
            <Grid container className={classes.container}>
              <Grid item xs={4} sm={4} md={2}>
                <Availabilities day="Monday" />
              </Grid>
              <Grid item xs={4} sm={4} md={2}>
                <Availabilities day="Tuesday" />
              </Grid>
              <Grid item xs={4} sm={4} md={2}>
                <Availabilities day="Wednesday" />
              </Grid>
              <Grid item xs={4} sm={4} md={2}>
                <Availabilities day="Thursday" />
              </Grid>
              <Grid item xs={4} sm={4} md={2}>
                <Availabilities day="Friday" />
              </Grid>
              <Grid item xs={4} sm={4} md={2}>
                <Availabilities day="Saturday" />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <div style={{ width: 200, margin: "10px auto 10px auto" }}>
          {" "}
          <Button>Save</Button>
        </div>
      </Grid>
    </Paper>
  );
};
