const styles = (theme) => ({
  loginPageWrapper: {
    flexGrow: 1,
    backgroundColor: theme.palette.color.white,
  },
  loginPageContainer: {
    margin: "auto",
    minHeight: "84.4vh",
  },
  middle: {
    margin: "50px auto auto auto",
  },
  image: {
    margin: "40px auto 20px auto",
    width: "55%",
  },
  formContainer: {
    backgroundColor: theme.palette.primary.white,
    [theme.breakpoints.down(600)]: {
      margin: "auto",
    },
  },
  textFieldContainer: {
    marginBottom: 20,
  },
  textForm: {
    fontFamily: theme.palette.font.montserrat,
    marginTop: -10,
    textAlign: "left",
  },
  rememberMe: {
    fontFamily: theme.palette.font.montserrat,
  },
  login: {
    color: theme.palette.color.orange,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.color.skyBlue,
    },
  },
  pageTitle: {
    margin: "10px auto 10px auto",
    fontSize: 28,
    textAlign: "center",
    fontWeight: 900,
  },
  haveAnAccount: {
    marginLeft: "4.5%",
  },
  forgetEmail: {
    marginLeft: "4.5%",
  },
  buttonWrapper: {
    width: "100%",
    marginLeft: "4.5%",
  },
});

export default styles;
