import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
// Components
import { Divider } from "../../components";

// styles
import styles from "./Styles";

const TextFieldInput = (props) => {
  const {
    classes,
    name,
    type,
    label,
    defaultValue,
    inputRef,
    error,
    icon,
    autofocus,
  } = props;
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={4} md={2} lg={2} className={classes.label}>
          {label}
        </Grid>
        <Grid item xs={12} sm={8} md={10} lg={10}>
          <TextField
            name={name}
            type={type}
            inputRef={inputRef}
            error={error}
            defaultValue={defaultValue}
            className={classes.textField}
            fullWidth
            autofocus={autofocus}
            size="small"
            variant="outlined"
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              endAdornment: icon,
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};
TextFieldInput.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextFieldInput);
