import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// MUI Stuff
import withStyles from "@material-ui/core/styles/withStyles";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

// styles
import styles from "./Styles";

// Components
import { Typography, Tooltip, Card } from "../../components";

const BranchCard = (props) => {
  const {
    classes,
    branchLogo,
    branchName,
    branchAddress,
    readMore,
    onClick,
    key,
  } = props;
  let history = useHistory();
  return (
    <Grid item xs={12} sm={4} md={4} lg={3} className={classes.cardWrapper}>
      <Card className={classes.card} key={key}>
        <div className={classes.cardMedia}>
          <CardMedia className={classes.media} image={branchLogo} />
        </div>

        <CardContent className={classes.contentWrapper}>
          <Typography color="inherit" variant="h4" className={classes.h1Text}>
            {branchName}
          </Typography>
          <Typography color="inherit" className={classes.paperLabel}>
            {branchAddress}
          </Typography>
        </CardContent>

        <CardActions>
          <div className={classes.cardButtonContainer}>
            <div className={classes.cardButtonAndEdit}>
              <Tooltip tip="View details" placement="top">
                <div
                  size="small"
                  color="inherit"
                  className={classes.cardButton}
                  onClick={onClick}
                >
                  {readMore}
                </div>
              </Tooltip>
              <div className={classes.editIconContainer}>
                <Tooltip tip={"Edit" + " " + branchName} placement="top">
                  <EditOutlinedIcon
                    className={classes.editIcon}
                    onClick={() => history.push("/branches/edit")}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </CardActions>
      </Card>
    </Grid>
  );
};

BranchCard.propTypes = {
  classes: PropTypes.object.isRequired,
  readMore: PropTypes.string,
  branchName: PropTypes.string.isRequired,
  branchAddress: PropTypes.string.isRequired,
  branchLogo: PropTypes.string.isRequired,
};

export default withStyles(styles)(BranchCard);
