import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

// styles
import styles from "./Styles";

// Components
import { Typography } from "../../components";

const TabHeader = (props) => {
  const { classes, header, changeColor } = props;
  return (
    <Grid item xs={12} sm={12}>
      <Typography
        variant="h6"
        gutterBottom
        className={classes.headerCentered}
        style={{ color: changeColor }}
      >
        {header}
      </Typography>
    </Grid>
  );
};
TabHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
};

export default withStyles(styles)(TabHeader);
