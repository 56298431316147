const styles = (theme) => ({
  pageHeaderContainer: {
    [theme.breakpoints.down(760)]: {
      marginTop: 40,
    },
  },
  pageBar: {
    height: 100,
    margin: "20px auto auto auto",
    [theme.breakpoints.down(600)]: {
      margin: " 0px auto auto auto",
    },
  },
  languageContainer: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
    },
    [theme.breakpoints.down(415)]: {
      marginLeft: 15,
    },
    [theme.breakpoints.down(321)]: {
      marginLeft: 10,
    },
  },
  headerAndAddIcon: {
    display: "flex",
    margin: "auto",
    justifyContent: "center",
  },
  addIcon: {
    height: 25,
    marginTop: 20,
    marginLeft: 20,
    cursor: "pointer",
  },
  formControl: {
    minWidth: 120,
  },
  selectField: {
    height: "30px!important",
    backgroundColor: theme.palette.color.skyBlue,
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.color.white,
    fontFamily: theme.palette.font.montserrat,
  },
  pageTitle: {
    textAlign: "center",
    fontWeight: 1200,
    color: theme.palette.primary.main,
    fontFamily: theme.palette.font.montserrat,
    width: "100%",
  },
  options: {
    backgroundColor: theme.palette.color.skyBlue,
  },

  logoAndTitleContainer: {
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },
  searchContainer: {
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },

  search: {
    border: `1px solid ${theme.palette.color.skyBlue}`,
    width: "70%",
    position: "relative",
    borderRadius: 5,
    height: 30,
    float: "right",
    "&:hover": {
      // backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    [theme.breakpoints.up("xs")]: {
      margin: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      margin: "auto",
    },
  },
  searchIcon: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    fontFamily: theme.palette.font.montserrat,
    height: 30,
    paddingTop: -20,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(2em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
});

export default styles;
