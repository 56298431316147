import React, { useState } from "react";

// MUI stuff
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { Typography } from "..";
import { makeStyles } from "@material-ui/core/styles";
import ShortTextField from "../TextField/ShortTextField";
import { Button } from "..";
import { MenuItem } from "@material-ui/core";
import { TextField } from "@material-ui/core";
// components
import SimpleCheckbox from "../checkbox/checkbox";
import Checkbox from "../checkbox/SimpleCheckbox";
import TextFieldPassword from "./TextFieldPassword";

const licenceList = ["Licence 1", "Licence 2", "Licence 3"];
const employeeList = ["employee 1", "employee 2", "employee 3"];

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: theme.palette.font.montserrat,
    height: 20,
    backgroundColor: theme.palette.color.lighterGrey,
    fontSize: 12,
    fontWeight: "bold",
    width: "100%",
    paddingLeft: 10,
  },
  leftContainer: {
    borderRight: `1px solid ${theme.palette.color.lighterGrey}`,
  },
  tableContainer: {
    border: `1px solid ${theme.palette.color.lighterGrey}`,
    margin: "auto 10px 10px 10px",
    minHeight: 360,
  },
  tableContainerPart2: {
    border: `1px solid ${theme.palette.color.lighterGrey}`,
    margin: "auto 10px 10px 10px",
    minHeight: 195,
  },
  rightContainer: {},
  section: {
    marginLeft: 20,
    fontSize: 11,
    fontFamily: theme.palette.font.montserrat,
    backgroundColor: theme.palette.color.simpleWhite,
    paddingLeft: 5,
    paddingRight: 5,
    color: theme.palette.color.orange,
  },
}));

export const ProfileForm = ({ header, editUser, addOrEditUser }) => {
  const classes = useStyles();

  return (
    <Paper elevation={0}>
      <Grid item xs={12} sm={12} className={classes.header}>
        <Typography variant="p">{header}</Typography>
      </Grid>
      <Grid container item xs={12} sm={12} className={classes.form}>
        <Grid item xs={12} sm={6} className={classes.leftContainer}>
          <Typography variant="p" className={classes.section}>
            Identification
          </Typography>
          <Paper elevation={0} className={classes.tableContainer}>
            <Grid
              container
              spacing={3}
              style={{ marginTop: 20, marginBottom: 15 }}
              className={classes.rightContainerdd}
            >
              <ShortTextField
                autofocus
                name="company"
                label="Licence :"
                type="text"
                defaultValue={addOrEditUser == "edit" ? "Licence 1" : ""}
                select
                fullWidth
                list={licenceList.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
                // icon={<BusinessIcon />}
              />
              <ShortTextField
                name="code"
                label="Code :"
                type="text"
                defaultValue={addOrEditUser == "edit" ? editUser.code : ""}
                // icon={<BusinessIcon />}
              />
              <TextFieldPassword
                label="Password :"
                user={editUser}
                addOrEditUser={addOrEditUser}
                // icon={<BusinessIcon />}
              />
              <ShortTextField
                autofocus
                name="employee"
                label="Employee :"
                type="text"
                defaultValue={addOrEditUser == "edit" ? "employee 2" : ""}
                select
                fullWidth
                list={employeeList.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
                // icon={<BusinessIcon />}
              />

              <ShortTextField
                name="firstName"
                label="First Name :"
                type="text"
                defaultValue={addOrEditUser == "edit" ? editUser.firstName : ""}
                // icon={<BusinessIcon />}
              />

              <ShortTextField
                name="lastName"
                label="Last Name :"
                type="text"
                defaultValue={addOrEditUser == "edit" ? editUser.lastName : ""}
                // icon={<BusinessIcon />}
              />
              <Checkbox
                label="Active"
                ifChecked={addOrEditUser == "edit" ? "true" : "false"}
              />
              <Checkbox label="Administrator" ifChecked="false" />
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.leftContainer}>
          <Typography variant="p" className={classes.section}>
            Access to data
          </Typography>
          <Paper elevation={0} className={classes.tableContainer}>
            <Grid
              container
              spacing={3}
              style={{ marginTop: 20, marginBottom: 15 }}
              className={classes.rightContainerdd}
            >
              <SimpleCheckbox
                label="Company"
                ifChecked={addOrEditUser == "edit" ? "false" : "true"}
              />
              <SimpleCheckbox
                label="Branch"
                ifChecked={addOrEditUser == "edit" ? "true" : "false"}
              />
            </Grid>
          </Paper>
        </Grid>

        <div style={{ width: 200, margin: "10px auto 10px auto" }}>
          {" "}
          <Button>Save</Button>
        </div>
      </Grid>
    </Paper>
  );
};
