import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";

// styles
import styles from "./Styles";

// Components
import { Typography } from "../../components";

const HeaderAndPara = (props) => {
  const { classes, header, para } = props;
  return (
    <Paper elevation={0} className={classes.paper}>
      <Typography variant="h4" gutterBottom className={classes.header}>
        {header}
      </Typography>
      <p className={classes.text}>{para}</p>
    </Paper>
  );
};
HeaderAndPara.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderAndPara);
