import React from "react";
import DividerItem from "@material-ui/core/Divider";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    divider: {
      width: "97%",
      margin: "auto auto 5px auto",
      [theme.breakpoints.down(541)]: {
        width: "94%",
      },
    },
  })
);

export const Divider = ({ backgroundColor }) => {
  const classes = useStyles();
  return (
    <DividerItem
      className={classes.divider}
      style={{ backgroundColor: backgroundColor }}
    />
  );
};
