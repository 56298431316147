import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import BusinessIcon from "@material-ui/icons/Business";
import EmailIcon from "@material-ui/icons/Email";
import HomeIcon from "@material-ui/icons/Home";
import PhoneIcon from "@material-ui/icons/Phone";
import PersonIcon from "@material-ui/icons/Person";
import HeaderAndPara from "../../components/HeaderAndPara/HeaderAndPara";

// styles
import styles from "./Styles";

// Components
import SimpleTextField from "../../components/TextField/SimpleTextField";
// Components
import { Typography, Button } from "../../components";

// Images
import AppIcon from "../../images/logo.svg";

const RegistrationPage = (props) => {
  const { register, handleSubmit, errors, reset } = useForm({
    //   resolver: yupResolver(signinSchema),
  });

  const [checkedIds, setCheckedIds] = useState([]);

  function handleCheck(event) {
    const checkedId = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setCheckedIds([...checkedIds, checkedId]);
    } else {
      setCheckedIds(checkedIds.filter((id) => id !== checkedId));
    }
  }
  let history = useHistory();
  const handleSignIn = async (data) => {
    console.log("Signin data:", data);
    try {
      history.push("/login");
      // reset(defaultValues);
    } catch (e) {
      console.log(e);
    }
  };

  const { classes } = props;
  return (
    <Grid container className={classes.registrationPageWrapper}>
      <Grid item xs={12} sm={6} className={classes.registrationPageContainer1}>
        <Grid item sx={12} sm={6} md={5} lg={10} className={classes.left}>
          <Typography variant="h1" className={classes.pageTitle}>
            Contact
          </Typography>
          <br />
          <br />
          <br />
          <br />
          <HeaderAndPara
            header="Vous avez une idée, un projet en tête ou des questions ?"
            para={
              <p>
                Nous offrons des services de développement d’application web et
                mobile ainsi que des services-conseils destinés aux start-ups et
                aux entreprises.
                <br />
                <br />
                EJSOFT se distingue par son désir d’accompagner les entreprises
                et de faire une réelle différence en offrant un produit
                personnalisé et de qualité.
              </p>
            }
          />
          <div className={classes.haveAnAccount}>
            <PhoneIcon />{" "}
            <a className={classes.login} href="tel:+1438837-4108">
              <span className={classes.phone}> +1 (438) 837-4108</span>
            </a>
          </div>
          <div className={classes.haveAnAccount}>
            <EmailIcon />{" "}
            <span className={classes.login}>eljos45@yahoo.fr</span>
          </div>

          <div className={classes.haveAnAccount}>
            <BusinessIcon />{" "}
            <span className={classes.login}>
              1070 chemin du Coteau, Terrebonne, QC, J6W5H2, Canada
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.registrationPageContainer}>
        <Grid item sx={12} sm={6} md={5} lg={9} className={classes.right}>
          <div style={{ textAlign: "center" }}>
            <img src={AppIcon} alt="logo-portrait" className={classes.image} />
          </div>
          <Typography variant="h2" className={classes.pageTitle}>
            Remplissez le formulaire ci-dessous ou contactez-nous directement.
          </Typography>
          <Grid xs={11} sm={12} className={classes.formContainer}>
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(handleSignIn)}
            >
              <Grid item xs={12} sm={12} className={classes.textFieldContainer}>
                <SimpleTextField
                  name="name"
                  type="text"
                  label="Nom de la Companie"
                  icon={<BusinessIcon />}
                />
              </Grid>

              <Grid item xs={12} sm={12} className={classes.textFieldContainer}>
                <SimpleTextField
                  name="email"
                  label="Courrier de la Companie"
                  icon={<EmailIcon />}
                />
              </Grid>

              <Grid item xs={12} sm={12} className={classes.textFieldContainer}>
                <SimpleTextField
                  name="address"
                  label="Addresse de la Companie"
                  icon={<HomeIcon />}
                />
              </Grid>

              <Grid item xs={12} sm={12} className={classes.textFieldContainer}>
                <SimpleTextField
                  name="Phone"
                  type="text"
                  label="Telephone"
                  icon={<PhoneIcon />}
                />
              </Grid>

              <Grid item xs={12} sm={12} className={classes.textFieldContainer}>
                <SimpleTextField
                  name="firsName"
                  type="text"
                  label="Prenom du Contact"
                  icon={<PersonIcon />}
                />
              </Grid>

              <Grid item xs={12} sm={12} className={classes.textFieldContainer}>
                <SimpleTextField
                  name="lastName"
                  type="text"
                  label="Nom du Contact"
                  icon={<PersonIcon />}
                />
              </Grid>

              <Grid item xs={12} sm={12} className={classes.textFieldContainer}>
                <SimpleTextField
                  name="branch"
                  type="text"
                  label="Choisir une Branche"
                  icon={<BusinessIcon />}
                />
              </Grid>
              <br />
              <div className={classes.buttonWrapper}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  S'inscrire
                </Button>
              </div>

              <br />
              <small className={classes.haveAnAccount}>
                Vous avez déjà un compte?{" "}
                <Link to="/login">
                  <span className={classes.login}>Se Connecter {">"} </span>
                </Link>
              </small>

              <br />
              <br />
            </form>
            <div className={classes.blankSpace}></div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
RegistrationPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RegistrationPage);
