import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

// styles
import styles from "./Styles";

// Images
import RoundCheckbox from "../../images/roundCheckbox.svg";

const ParaWithRoundCheckbox = (props) => {
  const { classes, para } = props;
  return (
    <Grid item xs={12} sm={12} className={classes.checkboxAndPara}>
      <div edge="start" className={classes.logoWrapper}>
        <img src={RoundCheckbox} alt="logo" className={classes.logo} />
      </div>
      <div className={classes.textContainer}>
        <p className={classes.text}>{para}</p>
      </div>
    </Grid>
  );
};
ParaWithRoundCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ParaWithRoundCheckbox);
