import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";

// styles
import styles from "./Styles";

const Detail = (props) => {
  const { classes, staticText, dynamicText } = props;
  return (
    <div className={classes.detail}>
      <span className={classes.staticText}>{staticText}: </span> {dynamicText}
    </div>
  );
};
Detail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Detail);
