const styles = (theme) => ({
  branchPageWrapper: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.color.white,
  },

  branchesContainer: {
    margin: "auto",
    minHeight: "100vh",
    [theme.breakpoints.down(600)]: {
      marginTop: 70,
    },
  },

  displayBranches: {
    backgroundColor: theme.palette.color.pinkGrey,
    margin: "30px 20px 20px 20px",
    [theme.breakpoints.down(960)]: {
      marginTop: 90,
    },
    [theme.breakpoints.down(760)]: {
      marginTop: 130,
    },
  },
  mediaWrapper: {
    margin: "auto",
  },
  modal: {
    display: "flex",
    paddingTop: 100,
    justifyContent: "center",
    backgroundColor: "rgba(192, 191, 189, 0.103)",
    [theme.breakpoints.down(415)]: {
      paddingTop: 120,
    },
    [theme.breakpoints.down(541)]: {
      paddingTop: 115,
    },
    [theme.breakpoints.down(321)]: {
      paddingTop: 100,
    },
  },
  modalWrapper: {
    height: 500,
  },

  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  ul: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
});

export default styles;
