const styles = (theme) => ({
  modal: {
    display: "flex",
    paddingTop: 100,
    justifyContent: "center",
    backgroundColor: "rgba(192, 191, 189, 0.103)",
  },
  modalWrapper: {
    height: 200,
  },

  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
});

export default styles;
