const styles = (theme) => ({
  logoGrid: {
    backgroundColor: theme.palette.primary.main,
    marginTop: 2,
    height: "90vh",
    [theme.breakpoints.down(960)]: {
      height: 500,
    },
    [theme.breakpoints.down(860)]: {
      height: 450,
    },
    [theme.breakpoints.down(760)]: {
      height: 400,
    },
    [theme.breakpoints.down(600)]: {
      height: 300,
    },
    [theme.breakpoints.down(376)]: {
      height: 200,
    },
  },
  logoWrapper: {
    margin: "auto",
  },
  logo: {
    height: 100,
    [theme.breakpoints.down(860)]: {
      height: 80,
    },
    [theme.breakpoints.down(760)]: {
      height: 70,
    },
    [theme.breakpoints.down(376)]: {
      height: 60,
    },
  },
});

export default styles;
