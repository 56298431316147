import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

// styles
import styles from "./Styles";

const Popup = (props) => {
  const { classes, handleClose, open, details } = props;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open} className={classes.modalWrapper}>
        <Grid item xs={10} sm={11}>
          <Grid item sx={12} sm={12}>
            {details}
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};
Modal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Popup);
