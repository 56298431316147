import React from "react";

// Mui Stuff
import TooltipButton from "@material-ui/core/Tooltip";

export const Tooltip = ({ children, onClick, tip, placement }) => (
  <TooltipButton title={tip} placement={placement} onClick={onClick}>
    {children}
  </TooltipButton>
);
