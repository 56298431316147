const styles = (theme) => ({
  usersPageWrapper: {
    flexGrow: 1,
    backgroundColor: theme.palette.color.white,
  },
  usersContainer: {
    margin: "auto",
    minHeight: "100vh",
    [theme.breakpoints.down(960)]: {
      marginTop: 70,
    },
  },

  mediaWrapper: {
    margin: "auto",
  },
  displayUsers: {
    backgroundColor: theme.palette.color.pinkGrey,
    margin: "30px 20px 20px 20px",
    [theme.breakpoints.down(960)]: {
      marginTop: 100,
    },
    [theme.breakpoints.down(760)]: {
      marginTop: 130,
    },
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  ul: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
  },
});

export default styles;
