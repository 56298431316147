import JohnDonPic from "../images/johnDoe.svg";
import aliciaPendryPic from "../images/aliciaPendry.svg";
import JanneDoePic from "../images/janneDoe.svg";
import Nick from "../images/nick.svg";

const db = {
  users: [
    {
      userId: "dsdsd566j5h343432332565gf43",
      image: JohnDonPic,
      firstName: "John",
      lastName: "Doe",
      dob: "22-04-2012",
      email: "john@gmail.com",
      password: "user1234",
      code: "John-Doe1234",
      company: "Comapny 1",

      emailType: "Office",
      phone: "509 43433232",
      workPhone: "509 324409998",
      phoneType: "Portable",
      town: "Quebec",
      codeTown: "QC",
      country: "Canada",
      codeCountry: "CA",
      province: "Otario",
      codeProvince: "OT",
      startingDate: "23-04-2012",
      endingDate: "01-12-2021",
      position: "Manager",
      branch: "Car Dealer",
      description: "Technician",
      address2: "349, Antagonish, Canada",
      address1: "203, Quebec, Canada",
    },

    {
      userId: "5454d566j5h343432332565gf44",
      image: aliciaPendryPic,
      firstName: "Elie",
      position: "CEO",
      lastName: "Louis",
      dob: "22-04-2012",
      email: "user2@gmail.com",
      password: "user1234",
      code: "Elie-CEO1234",
      company: "Comapny 2",

      emailType: "Office",
      phone: "509 43333232",
      workPhone: "509 324409998",
      phoneType: "Portable",
      town: "Quebec",
      codeTown: "QC",
      country: "Canada",
      codeCountry: "CA",
      province: "Otario",
      codeProvince: "OT",
      startingDate: "23-04-2012",
      endingDate: "01-12-2021",
      branch: "Car Dealer",
      address1: "203, Quebec, Canada",
      description: "Technician",
      address2: "349, Antagonish, Canada",
    },

    {
      userId: "dsdsd566j5h343432332565gf43",
      image: JohnDonPic,
      firstName: "John",
      lastName: "Doe",
      dob: "22-04-2012",
      email: "john@gmail.com",
      password: "user1234",
      code: "John-Doe1234",
      company: "Comapny 3",

      emailType: "Office",
      phone: "509 43433232",
      workPhone: "509 324409998",
      phoneType: "Portable",
      town: "Quebec",
      codeTown: "QC",
      country: "Canada",
      codeCountry: "CA",
      province: "Otario",
      codeProvince: "OT",
      startingDate: "23-04-2012",
      endingDate: "01-12-2021",
      position: "Manager",
      branch: "Car Dealer",
      description: "Technician",
      address2: "349, Antagonish, Canada",
      address1: "203, Quebec, Canada",
    },

    {
      userId: "212sd566j5h343432332565gf45",
      image: Nick,
      firstName: "John",
      position: "Secretary",
      lastName: "Doe",
      dob: "22-04-2012",
      email: "user4@gmail.com",
      password: "user1234",
      code: "John-Doe1234",
      company: "Comapny 4",

      emailType: "Office",
      phone: "509 43433232",
      workPhone: "509 324409998",
      phoneType: "Portable",
      town: "Quebec",
      codeTown: "QC",
      country: "Canada",
      codeCountry: "CA",
      province: "Otario",
      codeProvince: "OT",
      startingDate: "23-04-2012",
      endingDate: "01-12-2021",
      branch: "Car Dealer",
      address1: "203, Quebec, Canada",
      description: "Technician",
      address2: "309, Quebec, Canada",
    },

    {
      userId: "7hgsd566j5h343432332565gf46",
      image: JohnDonPic,
      firstName: "John",
      position: "Secretary",
      lastName: "Doe",
      dob: "22-04-2012",
      email: "user5@gmail.com",
      password: "user1234",
      code: "John-Doe1234",
      company: "Comapny 5",

      emailType: "Office",
      phone: "509 43433232",
      workPhone: "509 324409998",
      phoneType: "Portable",
      town: "Quebec",
      codeTown: "QC",
      country: "Canada",
      codeCountry: "CA",
      province: "Otario",
      codeProvince: "OT",
      startingDate: "23-04-2012",
      endingDate: "01-12-2021",
      branch: "Car Dealer",
      address1: "203, Quebec, Canada",
      description: "Technician",
      address2: "349, Antagonish, Canada",
    },

    {
      userId: "zzazd566j5h343432332565gf49",
      image: aliciaPendryPic,
      firstName: "Johnny",
      position: "Secretary",
      lastName: "Pee",
      dob: "22-04-2011",
      email: "user6@gmail.com",
      password: "user1234",
      code: "John-Doe1234",
      company: "Comapny 6",

      emailType: "Office",
      phone: "509 43433232",
      workPhone: "509 324409998",
      phoneType: "Portable",
      town: "Quebec",
      codeTown: "QC",
      country: "Canada",
      codeCountry: "CA",
      province: "Otario",
      codeProvince: "OT",
      startingDate: "23-04-2012",
      endingDate: "01-12-2021",
      branch: "Car Dealer",
      address1: "203, Quebec, Canada",
      description: "Technician",
      address2: "349, Antagonish, Canada",
    },

    {
      userId: "zzazd566j5h343432332565gf49",
      image: JohnDonPic,
      firstName: "John",
      position: "Secretary",
      lastName: "Doe",
      dob: "22-04-2012",
      email: "user@gmail.com",
      password: "user1234",
      code: "John-Doe1234",
      company: "Comapny 7",

      emailType: "Office",
      phone: "509 43433232",
      workPhone: "509 324409998",
      phoneType: "Portable",
      town: "Quebec",
      codeTown: "QC",
      country: "Canada",
      codeCountry: "CA",
      province: "Otario",
      codeProvince: "OT",
      startingDate: "23-04-2012",
      endingDate: "01-12-2021",
      branch: "Car Dealer",
      address1: "203, Quebec, Canada",
      description: "Technician",
      address2: "349, Antagonish, Canada",
    },

    {
      userId: "zzazd566j5h343432332565gf49",
      image: aliciaPendryPic,
      firstName: "John",
      position: "Secretary",
      lastName: "Doe",
      dob: "22-04-2012",
      email: "user@gmail.com",
      password: "user1234",
      code: "John-Doe1234",
      company: "Comapny 8",

      emailType: "Office",
      phone: "509 43433232",
      workPhone: "509 324409998",
      phoneType: "Portable",
      town: "Quebec",
      codeTown: "QC",
      country: "Canada",
      codeCountry: "CA",
      province: "Otario",
      codeProvince: "OT",
      startingDate: "23-04-2012",
      endingDate: "01-12-2021",
      branch: "Car Dealer",
      address1: "203, Quebec, Canada",
      description: "Technician",
      address2: "349, Antagonish, Canada",
    },

    {
      userId: "dsdsd566j5h343432332565gf43",
      image: JohnDonPic,
      firstName: "John",
      position: "Manager",
      lastName: "Doe",
      dob: "22-04-2012",
      email: "user@gmail.com",
      password: "user1234",
      code: "John-Doe1234",
      company: "Comapny 9",

      emailType: "Office",
      phone: "509 43433232",
      workPhone: "509 324409998",
      phoneType: "Portable",
      town: "Quebec",
      codeTown: "QC",
      country: "Canada",
      codeCountry: "CA",
      province: "Otario",
      codeProvince: "OT",
      startingDate: "23-04-2012",
      endingDate: "01-12-2021",
      branch: "Car Dealer",
      address1: "203, Quebec, Canada",
      description: "Technician",
      address2: "349, Antagonish, Canada",
    },

    {
      userId: "5454d566j5h343432332565gf44",
      image: aliciaPendryPic,
      firstName: "John",
      position: "CEO",
      lastName: "Doe",
      dob: "22-04-2012",
      email: "user@gmail.com",
      password: "user1234",
      code: "John-Doe1234",
      company: "Comapny 10",

      emailType: "Office",
      phone: "509 43433232",
      workPhone: "509 324409998",
      phoneType: "Portable",
      town: "Quebec",
      codeTown: "QC",
      country: "Canada",
      codeCountry: "CA",
      province: "Otario",
      codeProvince: "OT",
      startingDate: "23-04-2012",
      endingDate: "01-12-2021",
      branch: "Car Dealer",
      address1: "203, Quebec, Canada",
      description: "Technician",
      address2: "349, Antagonish, Canada",
    },

    {
      userId: "212sd566j5h343432332565gf45",
      image: Nick,
      firstName: "John",
      position: "Secretary",
      lastName: "Doe",
      dob: "22-04-2012",
      email: "user@gmail.com",
      emailType: "Office",
      code: "John-Doe1234",
      company: "Comapny 11",

      password: "user1234",
      phone: "509 43433232",
      workPhone: "509 324409998",
      phoneType: "Portable",
      town: "Quebec",
      codeTown: "QC",
      country: "Canada",
      codeCountry: "CA",
      province: "Otario",
      codeProvince: "OT",
      startingDate: "23-04-2012",
      endingDate: "01-12-2021",
      branch: "Car Dealer",
      address1: "203, Quebec, Canada",
      description: "Technician",
      address2: "349, Antagonish, Canada",
    },

    {
      userId: "7hgsd566j5h343432332565gf46",
      image: JohnDonPic,
      firstName: "John",
      position: "Secretary",
      lastName: "Doe",
      dob: "22-04-2012",
      email: "user@gmail.com",
      password: "user1234",
      emailType: "Office",
      code: "John-Doe1234",
      company: "Comapny 12",

      phone: "509 43433232",
      workPhone: "509 324409998",
      phoneType: "Portable",
      town: "Quebec",
      codeTown: "QC",
      country: "Canada",
      codeCountry: "CA",
      province: "Otario",
      codeProvince: "OT",
      startingDate: "23-04-2012",
      endingDate: "01-12-2021",
      branch: "Car Dealer",
      address1: "203, Quebec, Canada",
      description: "Technician",
      address2: "349, Antagonish, Canada",
    },

    {
      userId: "zzazd566j5h343432332565gf49",
      image: aliciaPendryPic,
      firstName: "John",
      position: "Secretary",
      lastName: "Doe",
      dob: "22-04-2012",
      email: "user@gmail.com",
      emailType: "Office",
      code: "John-Doe1234",
      company: "Comapny 13",

      password: "user1234",
      phone: "509 43433232",
      workPhone: "509 324409998",
      phoneType: "Portable",
      town: "Quebec",
      codeTown: "QC",
      country: "Canada",
      codeCountry: "CA",
      province: "Otario",
      codeProvince: "OT",
      startingDate: "23-04-2012",
      endingDate: "01-12-2021",
      branch: "Car Dealer",
      address1: "203, Quebec, Canada",
      description: "Technician",
      address2: "349, Antagonish, Canada",
    },

    {
      userId: "zzazd566j5h343432332565gf49",
      image: JohnDonPic,
      firstName: "Johnny",
      position: "Security Guard",
      lastName: "Pee",
      dob: "22-04-2012",
      email: "user@gmail.com",
      emailType: "Office",
      code: "John-Doe1234",
      company: "Comapny 14",

      password: "user1234",
      phone: "509 43433232",
      workPhone: "509 324409998",
      phoneType: "Portable",
      town: "Quebec",
      codeTown: "QC",
      country: "Canada",
      codeCountry: "CA",
      province: "Otario",
      codeProvince: "OT",
      startingDate: "23-04-2012",
      endingDate: "01-12-2021",
      branch: "Car Dealer",
      address1: "203, Quebec, Canada",
      description: "Technician",
      address2: "349, Antagonish, Canada",
    },

    {
      userId: "zzazd566j5h343432332565gf49",
      image: aliciaPendryPic,
      firstName: "John",
      position: "Secretary",
      lastName: "Doe",
      dob: "22-04-2012",
      password: "user1234",
      email: "user@gmail.com",
      emailType: "Office",
      code: "John-Doe1234",
      company: "Comapny 15",

      phone: "509 43433232",
      workPhone: "509 324409998",
      phoneType: "Portable",
      town: "Quebec",
      codeTown: "QC",
      country: "Canada",
      codeCountry: "CA",
      province: "Otario",
      codeProvince: "OT",
      startingDate: "23-04-2012",
      endingDate: "01-12-2021",
      branch: "Web Developer",
      address1: "203, Quebec, Canada",
      description: "Technician",
      address2: "349, Antagonish, Canada",
    },

    {
      userId: "5454d566j5h343432332565gf44",
      image: aliciaPendryPic,
      firstName: "Sally",
      position: "Car repairer",
      lastName: "Brooks",
      dob: "22-04-2012",
      email: "user@gmail.com",
      emailType: "Office",
      code: "John-Doe1234",
      company: "Comapny 16",

      password: "user1234",
      phone: "509 43433232",
      workPhone: "509 324409998",
      phoneType: "Portable",
      town: "Quebec",
      codeTown: "QC",
      country: "Canada",
      codeCountry: "CA",
      province: "Otario",
      codeProvince: "OT",
      startingDate: "23-04-2012",
      endingDate: "01-12-2021",
      branch: "Car Dealer",
      address1: "103, Quebec, Canada",
      description: "Technician",
      address2: "349, Antagonish, Canada",
    },
  ],
};

export default db;
