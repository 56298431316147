import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

// styles
import styles from "./Styles";

// Components
import TabHeader from "../../components/TabHeader/TabHeader";
import Detail from "../../components/Detail/Detail";

// Images
import CloseImg from "../../images/close.svg";

const AppDetails = ({ classes, employee, key, onClickClose }) => (
  <Grid item sx={12} sm={12} key={key}>
    <Paper
      variant="outlined"
      elevation={0}
      className={classes.employeeFullDetailsWrapper}
    >
      <Grid className={classes.avatarContainer}>
        <div className={classes.header}>
          <div onClick={onClickClose}>
            <img src={CloseImg} alt="Close" className={classes.logo} />
          </div>
          <TabHeader
            header={"Edit " + employee.firstName + " " + employee.lastName}
            changeColor="#ec8f56"
          />
          <div className={classes.employeeImage}>
            <img
              src={employee.image}
              alt={employee.firstName + " " + "photo"}
              className={classes.logoD}
            />
          </div>
        </div>
        <Grid
          container
          item
          xs={12}
          sm={11}
          md={10}
          lg={10}
          className={classes.paperUserDetails}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={classes.detailsLeft}
          >
            <Detail
              staticText="First Name  "
              dynamicText={employee.firstName}
            />
            <Detail staticText="Last Name " dynamicText={employee.lastName} />
            <Detail staticText="Date of Birth " dynamicText={employee.dob} />
            <Detail staticText="E-mail" dynamicText={employee.email} />
            <Detail staticText="Phone" dynamicText={employee.phone} />
            <Detail staticText="Work Phone" dynamicText={employee.workPhone} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={classes.detailsRight}
          >
            <Detail staticText="Address 1" dynamicText={employee.address1} />
            <Detail staticText="Address 2" dynamicText={employee.address2} />
            <Detail
              staticText="Associated Branch"
              dynamicText={employee.branch}
            />
            <Detail staticText="Position" dynamicText={employee.position} />
            <Detail
              staticText="Starting Date"
              dynamicText={employee.startingDate}
            />
            <Detail
              staticText="Ending Date"
              dynamicText={employee.endingDate}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  </Grid>
);

AppDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppDetails);
