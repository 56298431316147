import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

// styles
import styles from "./Styles";
// Images
import CloseImg from "../../images/close.svg";

const AppDetails = ({ classes, branch, key, onClickClose }) => (
  <Grid item sx={12} sm={12} key={key}>
    <Paper
      variant="outlined"
      elevation={0}
      className={classes.userFullDetailsWrapper}
    >
      <Grid className={classes.avatarContainer}>
        <div className={classes.title}>
          <div onClick={onClickClose}>
            <img src={CloseImg} alt="Close" className={classes.logo} />
          </div>
          <h3>
            <span className={classes.titleDots}>*** </span>
            About {branch.name}
            <span className={classes.titleDots}>***</span>
          </h3>
          <div className={classes.icon}>
            <img
              src={branch.image}
              alt="logo"
              style={{
                width: "20%",
                objectFit: "contain",
                backgroundSize: "contain",
              }}
            />
          </div>
        </div>
        <div className={classes.description}>
          <p className={classes.text}>{branch.address}</p>
        </div>
        <div className={classes.description}>
          <p className={classes.text}>{branch.description}</p>
        </div>
      </Grid>
    </Paper>
  </Grid>
);

AppDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppDetails);
