import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Paper from "@material-ui/core/Paper";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

// Components
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  ContactsForm,
  ProfileForm,
  PhoneForm,
  CommentsForm,
  Button,
  Typography,
} from "../../components";

// styles
import styles from "./Styles";

function getSteps() {
  return ["Profile", "Contacts", "Phones", "Comments"];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <ProfileForm header="Profile Information" />;
    case 1:
      return <ContactsForm header="Contact Information" />;
    case 2:
      return <PhoneForm header="Other phones" />;
    case 3:
      return <CommentsForm header="Comments" />;
    default:
      return <ProfileForm header="Profile Information" />;
  }
}

const BranchForms = (props) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const { classes } = props;
  return (
    <Grid container className={classes.editBranchPageWrapper}>
      <Grid
        container
        item
        xs={12}
        sm={12}
        className={classes.editBranchPageContainer}
      >
        <PageHeader header="Add Branch" display="none" />

        <Grid item xs={12} sm={12} className={classes.tabContainer}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Grid>
            {activeStep === steps.length ? (
              <Grid>
                <Paper
                  elevation={0}
                  className={classes.paper}
                  style={{ textAlign: "center" }}
                >
                  <Typography>All steps completed</Typography>
                  <div style={{ width: 100, margin: "20px auto auto auto" }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                  </div>
                </Paper>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} className={classes.tabContainer}>
                <Typography>{getStepContent(activeStep)}</Typography>
                <div style={{ display: "flex" }}>
                  <Box mx={1} my={2} style={{ width: 100 }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                  </Box>
                  <Box my={2} style={{ width: 100 }}>
                    <Button
                      className="ml-4"
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </Box>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(BranchForms);
