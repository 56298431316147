const styles = (theme) => ({
  editBranchPageWrapper: {
    flexGrow: 1,
    backgroundColor: theme.palette.color.white,
  },
  editBranchPageContainer: {
    margin: "auto",
    minHeight: "100vh",
    [theme.breakpoints.down(960)]: {
      marginTop: 70,
    },
  },
  tabContainer: {
    backgroundColor: theme.palette.color.pinkGrey,
    margin: "30px 20px 20px 20px",
    border: `1px solid ${theme.palette.color.skyBlue}`,
    // backgroundColor: "red",
    // [theme.breakpoints.down(960)]: {
    //   marginTop: 90,
    // },
    // [theme.breakpoints.down(760)]: {
    //   marginTop: 130,
    // },
  },
  paper: {
    minHeight: 300,
  },

  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.color.skyBlue}`,
  },
  tabPageContainer: {
    minHeight: "72vh",
    backgroundColor: theme.palette.color.lemonGreen,
    fontFamily: theme.palette.font.montserrat,
  },
  tab: {
    backgroundColor: theme.palette.color.lemonGreen,
    color: theme.palette.color.thickGreen,
    fontWeight: 900,
  },
  tabItem: {
    // backgroundColor: "red",
  },
});

export default styles;
