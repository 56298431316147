import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

// styles
import styles from "./Styles";

// Images
import Star from "../../images/star.svg";

const AppDetails = ({ classes, item, key, onClick }) => (
  <Grid item sx={12} sm={12} key={key} onClick={onClick}>
    <Paper
      variant="outlined"
      elevation={0}
      className={classes.userFullDetailsWrapper}
    >
      <Grid className={classes.avatarContainer}>
        <div className={classes.title}>
          <h3>
            <span className={classes.titleDots}>*** </span>
            About {item.name}
            <span className={classes.titleDots}>***</span>
          </h3>
          <div className={classes.icon}>
            <img
              src={item.image}
              alt="logo"
              style={{
                width: "20%",
                objectFit: "contain",
                backgroundSize: "contain",
              }}
            />
          </div>
        </div>
        <div className={classes.description}>
          <p className={classes.text}>{item.description}</p>
        </div>
        <div className={classes.starsContainer}>
          <div className={classes.starsSmallContainer}>
            <div className={classes.icon}>
              <img
                src={Star}
                alt="logo"
                style={{
                  width: "80%",
                  objectFit: "contain",
                  backgroundSize: "contain",
                }}
              />
            </div>
            <div className={classes.icon}>
              <img
                src={Star}
                alt="logo"
                style={{
                  width: "80%",
                  objectFit: "contain",
                  backgroundSize: "contain",
                }}
              />
            </div>
            <div className={classes.icon}>
              <img
                src={Star}
                alt="logo"
                style={{
                  width: "80%",
                  objectFit: "contain",
                  backgroundSize: "contain",
                }}
              />
            </div>
            <div className={classes.icon}>
              <img
                src={Star}
                alt="logo"
                style={{
                  width: "80%",
                  objectFit: "contain",
                  backgroundSize: "contain",
                }}
              />
            </div>

            <div className={classes.icon}>
              <img
                src={Star}
                alt="logo"
                style={{
                  width: "80%",
                  objectFit: "contain",
                  backgroundSize: "contain",
                }}
              />
            </div>
          </div>
        </div>
      </Grid>
    </Paper>
  </Grid>
);

AppDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppDetails);
