import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import "./Navbar.css";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { useTheme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import List from "@material-ui/core/List";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

// // styles
import styles from "./Styles";

// Components
import Profile from "../../ProfileImage/ProfileImage";
import FooterSidebar from "../../Layout/FooterSidebar/FooterSidebar";

// Images
import ProfileImg from "../../../images/profile.svg";
import LogoutIcon from "../../../images/logout.png";

const Sidebar = (props) => {
  const { classes } = props;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const drawer = (
    <nav className={classes.toolbarContainer}>
      <div style={{ display: "flex" }}>
        <div>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
        </div>

        <div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon className={classes.chevron} />
            )}
          </IconButton>
        </div>
      </div>

      <Profile userImage={ProfileImg} fullName="Janne Doe" />
      <Divider className={classes.divider1} />
      <div className={classes.sidebarTab}>
        <ul className="nav-menu-items">
          {SidebarData.map((item, index) => {
            return (
              <li key={index} className={item.cName}>
                <NavLink
                  to={item.path}
                  color="inherit"
                  activeClassName="navbar__link2--active"
                  className="navbar__link2"
                >
                  {item.icon}
                  <span className={classes.textTab}>{item.title}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>

      <div className={classes.logoutContainer}>
        <div edge="start">
          <img src={LogoutIcon} alt="logo" />
        </div>

        <div edge="start" className={classes.logoutTextContainer}>
          <p className={classes.logoutText}>Logout</p>
        </div>
      </div>
      <Divider className={classes.divider} />
      <FooterSidebar />
    </nav>
  );

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer} aria-label="mailbox folders">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <List>{drawer}</List>
        </Drawer>
      </nav>
    </div>
  );
};
Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  window: PropTypes.func,
};

export default withStyles(styles)(Sidebar);
