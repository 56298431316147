/* eslint-disable-line no-useless-computed-key */
import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";

// styles
import styles from "./Styles";

// Static images
import Twitter from "../../../images/twitter.svg";
import Tictoc from "../../../images/tictoc.svg";
import Instagram from "../../../images/instagram.svg";
import Facebook from "../../../images/facebook.svg";

const FooterSidebar = (props) => {
  const { classes } = props;

  return (
    <>
      <div className={classes.footerContainer}>
        <p className={classes.footerText}>
          ©EJSOFT 2021. Tous les droits sont réservés.{" "}
        </p>
      </div>

      <div className={classes.socialMediaContainer}>
        <div className={classes.socialMedia}>
          <img src={Instagram} alt="Instagram" className={classes.logo} />
        </div>
        <div className={classes.socialMedia}>
          <img src={Tictoc} alt="Instagram" className={classes.logo} />
        </div>
        <div className={classes.socialMedia}>
          <img src={Twitter} alt="Instagram" className={classes.logo} />
        </div>
        <div className={classes.socialMedia}>
          <img src={Facebook} alt="Instagram" className={classes.logo} />
        </div>
      </div>
    </>
  );
};

FooterSidebar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FooterSidebar);
