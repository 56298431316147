import React from "react";
import PropTypes from "prop-types";

// MUI stuff
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

// styles
import styles from "./Styles";

// Components
import LogoHeader from "../../components/LogoHeader/LogoHeader";
import HeaderAndPara from "../../components/HeaderAndPara/HeaderAndPara";
import ParaWithRoundCheckbox from "../../components/ParaWithRoundCheckbox/ParaWithRoundCheckbox";
import ApplicationCard from "../../components/ApplicationCard/ApplicationCard";

import ApplicationDetails from "../../components/ApplicationDetails/ApplicationDetails";
import { Typography } from "../../components";

// Database
import appDB from "../../utils/dbSchema";

const LandingPage = (props) => {
  const { classes } = props;
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState("");

  const handleOpen = (id) => {
    const application = appDB.applications.find((app) => app.appId === id);
    setOpen(true);
    setItem(application);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid container className={classes.landingPageWrapper}>
      <Grid
        container
        item
        xs={12}
        sm={12}
        className={classes.landingPageContainer}
      >
        <LogoHeader />

        <Grid container item xs={11} sm={11} className={classes.aboutUsSection}>
          <Grid item xs={11} sm={6} className={classes.aboutUsContainer}>
            <HeaderAndPara
              header="A propos"
              para={
                <p>
                  Nous sommes une agence de développement d’application web et
                  mobile offrant du service-conseil et du développement sur
                  mesure destinés aux petites et moyennes entreprises.
                  <br />
                  <br />
                  Nous croyons en un développement d’application de pointe comme
                  passerelle vers des expériences uniques et innovantes pour des
                  produits d’excellence.
                  <br />
                  <br />
                  Avec EJSOFT, votre projet pourra enfin se concrétiser et votre
                  entreprise pourra atteindre de nouveaux standards
                </p>
              }
            />
          </Grid>

          <Grid item xs={11} sm={6} className={classes.ourServices}>
            <HeaderAndPara
              header="CE QUE NOUS FAISONS"
              para={
                <p>
                  Nous sommes une agence de développement d’application web et
                  mobile qui a vu le jour en 2016 suite à une demande
                  grandissante dans le milieu du développement numérique.
                  Autrement dit, EJSOFT est le résultat d’une passion pour les
                  nouvelles technologies et l’ambition d’atteindre un nouveau
                  standard dans le développement web et mobile. Nous offrons des
                  services-conseils dans tous les métiers du développement web
                  et mobile destiné aux start-ups et aux entreprises.
                  <br />
                  <br />
                  Nous croyons en un développement d’application de pointe comme
                  passerelle vers des expériences uniques et innovantes. EJSOFT
                  c’est aussi une philosophie d’entreprise au-delà des
                  frontières. Nous avons construit une équipe talentueuse de
                  personnes issues d’horizons divers ayant chacune une expertise
                  redoutable dans son domaine.
                </p>
              }
            />
          </Grid>
        </Grid>

        <Grid container item xs={11} sm={11} className={classes.topClients}>
          {/* <Grid item xs={12} sm={12}>
            <Typography
              variant="h4"
              gutterBottom
              className={classes.headerCentered}
            >
              NOS MEILLEURS CLIENTS
            </Typography>
          </Grid> */}

          <Grid container item xs={12} sm={12} className={classes.services}>
            <HeaderAndPara
              header="Nos Services"
              para="Notre service s’adresse à tout entrepreneur, aux entreprises bien établies ainsi qu’aux start-up qui souhaitent améliorer ou créer une solution technologique qui saura bien évoluer dans le temps."
            />
            <Grid item xs={4} className={classes.ourServicesContainer}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.subHeader}
              >
                Globalement
              </Typography>
              <ParaWithRoundCheckbox para="Intranets/Extranets" />
              <ParaWithRoundCheckbox para="Responsive Design et PWA" />
              <ParaWithRoundCheckbox para="Application web pour les entreprises" />
              <ParaWithRoundCheckbox para="Développement Cloud" />
              <ParaWithRoundCheckbox para="Conception de plateforme sur mesure" />
            </Grid>
            <Grid item xs={4} className={classes.ourServicesContainer}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.subHeader}
              >
                Front-end
              </Typography>
              <ParaWithRoundCheckbox para="React" />
              <ParaWithRoundCheckbox para="Ionic React" />
              <ParaWithRoundCheckbox para="NextJS" />
              <ParaWithRoundCheckbox para="Redux" />
              <ParaWithRoundCheckbox para="React Testing Library" />
              <ParaWithRoundCheckbox para="Jest" />
              <ParaWithRoundCheckbox para="Redux" />
              <ParaWithRoundCheckbox para="HTML" />
              <ParaWithRoundCheckbox para="CSS" />
            </Grid>
            <Grid item xs={4} className={classes.ourServicesContainer}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.subHeader}
              >
                Back-end
              </Typography>
              <ParaWithRoundCheckbox para="PHP" />
              <ParaWithRoundCheckbox para="Symphony" />
              <ParaWithRoundCheckbox para="Net Core" />
              <ParaWithRoundCheckbox para="NodeJS" />
              <ParaWithRoundCheckbox para="MySQL" />
              <ParaWithRoundCheckbox para="NoSQL" />
              <ParaWithRoundCheckbox para="ORM" />
              <ParaWithRoundCheckbox para="Architecture Micro-service" />
            </Grid>
            <Grid item xs={4} className={classes.ourServicesContainer}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.subHeader}
              >
                Mobile
              </Typography>
              <ParaWithRoundCheckbox para="Ionic FrameWork" />
              <ParaWithRoundCheckbox para="React Native" />
              <ParaWithRoundCheckbox para="Flutter" />
            </Grid>
            <Grid item xs={4} className={classes.ourServicesContainer}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.subHeader}
              >
                Infrastructure & CLoud Native
              </Typography>
              <ParaWithRoundCheckbox para="Azure" />
              <ParaWithRoundCheckbox para="Azure DevOps" />
              <ParaWithRoundCheckbox para="Docker" />
              <ParaWithRoundCheckbox para="Pipeline Bitbucket" />
              <ParaWithRoundCheckbox para="Azure Kubernetes GitHub" />
            </Grid>
            <Grid item xs={4} className={classes.ourServicesContainer}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.subHeader}
              >
                Méthodologie
              </Typography>
              <ParaWithRoundCheckbox para="UML (Unified Modeling Language)" />
              <ParaWithRoundCheckbox para="Scrum / Agile" />
              <ParaWithRoundCheckbox para="OOP (Object Oriented Programing)" />
              <ParaWithRoundCheckbox para="TDD (Test Driven Development)" />
            </Grid>
          </Grid>
          <Grid item xs={11} />
        </Grid>

        <Grid
          container
          item
          sx={12}
          sm={12}
          className={classes.ourApplications}
        >
          <Grid container className={classes.insight}>
            <Typography variant="h4" gutterBottom className={classes.header}>
              Nos Réalisations et Clients
            </Typography>
            <Grid
              container
              spacing={6}
              item
              sm={11}
              xs={12}
              className={classes.mediaWrapper}
            >
              {appDB.applications.map((app, index) => (
                <ApplicationCard
                  appLogo={app.image}
                  readMore="READ MORE"
                  key={index}
                  onClick={() => handleOpen(app.appId)}
                />
              ))}
            </Grid>
          </Grid>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open} className={classes.modalWrapper}>
              <Grid item xs={10} sm={8}>
                <Grid item sx={12} sm={12}>
                  <ApplicationDetails item={item} />
                </Grid>
              </Grid>
            </Fade>
          </Modal>
        </Grid>
      </Grid>
    </Grid>
  );
};
LandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LandingPage);
