const styles = (theme) => ({
  footerContainer: {
    width: "96%",
    margin: "auto",
  },
  footerText: {
    fontSize: 10,
    textAlign: "center",
    color: theme.palette.color.grey,
  },
  socialMediaContainer: {
    width: "60%",
    margin: "auto auto 10px auto",
    display: "flex",
    justifyContent: "space-between",
  },
  socialMedia: {
    cursor: "pointer",
  },
  logo: {
    height: 20,
  },
});

export default styles;
